import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAcademiaSolicitudesSFResponse } from '@shared/Interface/IAcademiaSolicitudesSFResponse';
import { Paginador } from '@shared/Interface/IPaginador.interface';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { DashboardService } from '@shared/service/Dashboard/dashboard.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import{ lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-retos-busqueda-sf',
  templateUrl: './retos-busqueda-sf.component.html',
  styleUrls: ['./retos-busqueda-sf.component.css']
})
export class RetosBusquedaSFComponent implements OnInit {

  title = {
    title: 'Mis búsquedas de Socios Formadores'    
  };

  lang : string = 'es';

  solicitudesSFAllList: IAcademiaSolicitudesSFResponse[] = [];
  solicitudesSFActualesList: IAcademiaSolicitudesSFResponse[] = [];
  solicitudesSFActualesMovilList: IAcademiaSolicitudesSFResponse[] = [];

  idAcademico: number ;
  estatus: string = "todas";
  registrosPagina: number = environment.RegistrosPagina;
  paginaActual: number = 1;
  paginaActualMasResultados: number = 1;
  totalPaginas: number = 0;
  pages: number[] = [];

  textOrdenarPor: string = "retos_busqueda_sf.ordenar_por";
  valorOrden : number = 0;

  constructor(
    private dashboardService: DashboardService,
    private paginadorLibs: PaginadorLibsComponent,
    private usuarioService : UsuariosService,
    private router: Router
    ) { }

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem('lang') ?? 'es';

    let userStore =   this.usuarioService.getCurrentUserData();
    if (userStore) {
      this.idAcademico = userStore.idUsuario;
    }

    await this.getListasolicitudesSFActivos();
    this.getPaginacion();
    this.obtenerRegistrosActuales(1);
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }

  async getListasolicitudesSFActivos() {
    try {
      let resultado = <any>await lastValueFrom(this.dashboardService.getAcademiaSolicitudesSF(this.idAcademico, this.estatus));
      this.solicitudesSFAllList = resultado;
    } catch (error) {
      console.error("Error al obtener los académicos buscando un SF");
    }
  }

  getPaginacion() {
    let paginador: Paginador = this.paginadorLibs.getPaginacion(this.solicitudesSFAllList.length, this.registrosPagina);
    this.totalPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;
  }

  obtenerRegistrosActuales(numPagina: number) {
    this.solicitudesSFActualesList = this.paginadorLibs.obtenerRegistrosActuales(numPagina, this.registrosPagina, this.solicitudesSFAllList);
    this.solicitudesSFActualesMovilList = this.solicitudesSFActualesList;
    this.paginaActual = numPagina;
    this.paginaActualMasResultados = numPagina;
  }


  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.solicitudesSFActualesMovilList = this.paginadorLibs.obtenerMasResultados(this.paginaActualMasResultados, this.registrosPagina, this.solicitudesSFAllList);
  }

  ordenarLista(keyword: string){
    try {

      switch (keyword) {
        case "nombreReto":
          this.textOrdenarPor = "retos_busqueda_sf.orden_A_Z";
          this.valorOrden = 1;
          this.solicitudesSFAllList = this.solicitudesSFAllList.sort(this.SortArrayByReto);
          break;

        case "SocioFormador":
          this.textOrdenarPor = "retos_busqueda_sf.orden_socio_formador";
          this.valorOrden = 2;
          this.solicitudesSFAllList = this.solicitudesSFAllList.sort(this.SortArrayBySocioFormador);
          break;

        default:
          this.textOrdenarPor = "retos_busqueda_sf.ordenar_por";
          this.valorOrden = 0;
          this.solicitudesSFAllList = this.solicitudesSFAllList.sort(this.SortArrayByIdSocioFormador);
          break;
      }

      this.paginaActual = 1;
      this.obtenerRegistrosActuales(this.paginaActual);

    } catch (error) {
      console.error("Error al ordenar la lista ",error);
    }
  }


  SortArrayByReto(x:IAcademiaSolicitudesSFResponse, y: IAcademiaSolicitudesSFResponse){
    return x.reto.localeCompare(y.reto);
  }

  SortArrayBySocioFormador(x:IAcademiaSolicitudesSFResponse, y: IAcademiaSolicitudesSFResponse){
    return x.nombreSocioFormador.localeCompare(y.nombreSocioFormador);
  }

  SortArrayByIdSocioFormador(x:IAcademiaSolicitudesSFResponse, y: IAcademiaSolicitudesSFResponse){
    return x.idSocioFormador - y.idSocioFormador;
  }

  verSolicitud(idReto: number, idAcademico: number,  idSolicitud: number)
  {
    let url =`/solicitud/academico/${idAcademico}/${idReto}/${idSolicitud}`;
    this.router.navigate([url]);
    //window.location.href=url; 
    // solicitud/:type/:idAcademico/:idReto/:idSolicitud
  }
}
