import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { lastValueFrom, tap } from "rxjs";
import { Router } from "@angular/router";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { environment } from "environments/environment";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import Swal from "sweetalert2";
import { AccountService } from "@shared/service/Account/account.service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { IArea, ICampus, IRegion } from "@shared/Interface/ICatalogos.interface";

@Component({
  selector: "app-perfil-tab",
  templateUrl: "./perfil-tab.component.html",
  styleUrls: ["./perfil-tab.component.css"],
})
export class PerfilTabComponent implements OnInit {
  @Input() isUpdate = false;
  @Input() mainContactObj: any;
  @Input() newContact = false;
  @Input() updateSocio = false;
  @Output() callbackShowContacts: EventEmitter<any> = new EventEmitter<any>();
  contactForm: FormGroup;
  img = "assets/img/avatar_sin.jpg";
  imgUrl = "assets/img/avatar_sin.jpg";
  userData: any;
  lbl_btnAccion: string = '';
  lbl_nombreContacto: string ='Nuevo Contacto';
  numContactos: number = 0;
  buttons = [
    {
      url: "https://google.com",
      text: "Editar foto",
    },
  ];

  campusData: ICampus[];
  areasData: IArea[];
  regionData: IRegion[];
  lbl_area_anterior: string = '';
  clavePerfil: string = '';

  constructor(
    private fb: FormBuilder,
    private contactosService: ContactosService,
    private usuariosService: UsuariosService,
    private toastr: ToastrService,
    private router: Router,
    private azureBlobStorage: AzureBlobStorageService,
    private accountService: AccountService,
    private catalogosService: CatalogosService,
    private SociosFormadoresService: SociosFormadoresService
  ) {}



  ngOnInit(): void {
    if(this.newContact){
      this.mainContactObj={idSocioFormador : 0};
    }

    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

      this.catalogosService
      .getAreas()
      .pipe(tap((resultado: IArea[]) => (this.areasData = resultado)))
      .subscribe();

    this.catalogosService
      .getRegiones()
      .pipe(tap((resultado: IRegion[]) => (this.regionData = resultado)))
      .subscribe();

      this.initializeForm(this.mainContactObj);
    this.clavePerfil = this.usuariosService.getCurrentUserData().clavePerfil;
  }

  initializeForm(contacto: IContacto | null) {

    if(isNaN(Number(contacto?.area))){
      this.lbl_area_anterior = contacto?.area ?? '';
    }


    this.contactForm = this.fb.group({
      nombre: [contacto?.nombre ?? "", Validators.required],
      apellidos: [contacto?.apellidos ?? "", Validators.required],
      area: [Number(contacto?.area), Validators.required],
      email: [contacto?.email ?? "", [Validators.required, Validators.email]],
      puesto: [contacto?.puesto ?? "", Validators.required],
      telefonoOficina: [contacto?.telefonoOficina ?? "", ],
      telefonoMovil: [contacto?.telefonoMovil ?? "", ],
      exatec: [contacto?.exatec ?? false, Validators.required],
      idRegion: [contacto?.region,],
      idCampus: [contacto?.campusConvenio, ]
    });
    if (this.newContact) {
      this.contactForm.controls["email"].enable();
      this.lbl_btnAccion = 'Crear';
      this.lbl_nombreContacto = 'Nuevo Contacto:'
    } else {
      this.lbl_nombreContacto = `Contacto: ${contacto?.nombre} ${contacto?.apellidos}`;
      this.lbl_btnAccion = 'Actualizar datos';
      this.contactForm.controls["email"].disable();
    }

    if(contacto)
      this.getUserData(contacto.idUsuario);

      let idSocioFormador = this.usuariosService.getCurrentUserData().idSocioFormador == 0 ?
                             Number(sessionStorage.getItem("idSocioFormador_banco"))
                            : this.usuariosService.getCurrentUserData().idSocioFormador;

      this.contactosService.getContactos(idSocioFormador,false).subscribe({
        next: (resultado: IContacto[])=> {
          this.numContactos = resultado.length;
        },
        error: (err)=>{
          this.numContactos = 0;
        }

      }
      );

  }

  updateContact() {
    let userStore = this.usuariosService.getCurrentUserData();

    if (this.newContact) {
      return this.saveNewContact();
    }

    if (this.isUpdate) {
      userStore = {
        ...userStore,
        idUsuario       : this.mainContactObj.idUsuario,
        idContacto      : this.mainContactObj.idContacto,
        idSocioFormador : this.mainContactObj.idSocioFormador,
        contactoPrincipal: this.mainContactObj.contactoPrincipal,
        idRegion        : this.contactForm.get('idRegion')?.value ?? this.mainContactObj.idRegion,
        idCampusConvenio: this.mainContactObj.idCampusConvenio
      };
    }

    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.contactosService
        .putContactos(userStore.idSocioFormador, {
          idUsuario: userStore.idUsuario,
          idContacto: userStore.idContacto,
          ...this.contactForm.value,
          exatec: this.contactForm.value.exatec,
          contactoPrincipal: userStore.contactoPrincipal,
          idUsuarioModifica: this.usuariosService.getCurrentUserData().idUsuario ,
          idCampus : this.contactForm.get('idCampus')?.value
        })
        .pipe(
          tap((resultado: any) => {
            if (resultado) {
              this.toastr.success("Actualizado Correctamente!");
              this.listenerShowContacts(resultado);
              if(this.mainContactObj.idUsuario === this.usuariosService.getCurrentUserData().idUsuario){
                window.history.back();
              }

            } else {
              this.toastr.error("Algo Salió mal!");
            }
          })
        )
        .subscribe();
    }
    return;

  }

  async saveNewContact() {

    let idSocioFormador = this.usuariosService.getCurrentUserData().idSocioFormador == 0
                        ? Number(sessionStorage.getItem("idSocioFormador_banco"))
                        : this.usuariosService.getCurrentUserData().idSocioFormador;

    this.contactForm.markAllAsTouched();

    let contactP = (this.numContactos === 0);

    if (this.contactForm.valid) {
      let request = {
        ...this.contactForm.value,
        exatec: this.contactForm.value.exatec,
        contactoPrincipal: contactP,
        idUsuarioRegistro: this.usuariosService.getCurrentUserData().idUsuario,
        clave: "mvpretos"
      };

      // Cuando es socio nuevo, se obtiene el SF de la sesión y se manda al servicio
      let tmpsf = sessionStorage.getItem("sf_json");
      let sf;
      if(tmpsf){
        sf = JSON.parse(tmpsf);
        try{
          let res = await lastValueFrom(this.SociosFormadoresService.postSocios({
          socioFormador: sf,
          contactoPrincipal: request}));

          if (res) {
            this.toastr.success("Socio Formador Guardado correctamente!");
            this.newContact = false;
            this.listenerShowContacts(res);
          }
        }
        catch(e:any){
          if(e.status == 405 ){
            this.toastr.error(e.message);
            return;
          }
          else{
            this.toastr.error("Algo Salió mal!");
            console.log(e);
          }
        }
      }else{
          try{
            let resultado = await lastValueFrom(this.contactosService
              .postContactos(idSocioFormador, request));
                if (resultado) {
                    this.toastr.success("Actualizado Correctamente!");
                    this.contactosService.putContactos(idSocioFormador,{...resultado, ...request}).subscribe();
                    this.newContact = false;
                    this.listenerShowContacts({idSocioFormador:idSocioFormador});
                    await lastValueFrom(this.usuariosService.putFotos({idUsuario: resultado.idUsuario, urlfotos:'https://mpstecstorage.blob.core.windows.net/mvpretos-user-images/reto_4.jpg'}));

                  } else {
                    this.toastr.error("Algo Salió mal!");
                  }
            }catch(e){
              if(e){
                this.toastr.error(e+"");
              }
              console.log(e);
            }
      }
    }
    else{
      this.toastr.warning("Por favor revisar que los datos estén completos");
    }
  }

  listenerShowContacts(socio?) {
    this.callbackShowContacts.emit({idSocioFormador: socio?.idSocioFormador ?? 0});
    window.history.back();
  }

  onClickButton(url: string) {
    console.log("click ");
  }

  onChangeCheckbox(e) {
    this.contactForm.get('exatec')?.patchValue( e.target.value );
  }

  getUserData(idUsuario) {
    this.usuariosService.getUsuarioById(idUsuario).subscribe((resOK) => {
      this.userData = resOK;
      if (!this.userData) this.router.navigate(["/"]);
      this.img =
        this.userData.urlFotoUsuario ||
        "https://mvretosstorage.blob.core.windows.net/mvpretos-user-images/reto_4.jpg";
      return this.userData;
    });
  }

  deleteContact() {
    this.contactosService
      .deleteContact(
        this.mainContactObj.idSocioFormador,
        this.mainContactObj.idContacto
      )
      .pipe(
        tap((resultado: any) => {
          if (resultado) {
            this.toastr.success(resultado['#result-set-1'][0].status + ' - ' + resultado['#result-set-1'][0].mensaje);
            this.listenerShowContacts({idSocioFormador:this.mainContactObj.idSocioFormador});
          } else {
            this.toastr.error("Algo Salió mal!");
          }
        })
      )
      .subscribe();
  }

  async selImagen(event: any) {
    let idUsr = this.mainContactObj.idUsuario;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;
    let fileName = this.getFileName(idUsr, selFile);

    await this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameUsuario,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_usuarSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_usuarURL}${fileName}`;
        this.usuariosService
          .putFotos({ idUsuario: idUsr, urlFoto: fileUrlLogo })
          .subscribe((resOK) => {
            this.img = fileUrlLogo;
            if(this.mainContactObj.idUsuario === this.usuariosService.getCurrentUserData().idUsuario)
            {
              this.usuariosService.updateCurrentObjectUserData(
                "urlFotoUsuario",
                fileUrlLogo
              );

              window.location.reload();
            }

          });
      });
  }

  getFileName(id, file) {
    return `user_${id}.${file.name.split(".").pop()}`;
  }

  eliminarCuenta() {

    Swal.fire({
      title: "¿Desea eliminar su cuenta?",
      text: "Es irreversible y perderá acceso a la plataforma.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        let idUsr = this.mainContactObj.idUsuario;
        this.contactosService
          .deleteContact( this.mainContactObj.idSocioFormador ,idUsr )
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Gestión de Usuarios.",
                  resultado['#result-set-1'][0].status + ' - ' + resultado['#result-set-1'][0].mensaje,
                  "success"
                );
                if(['socio'].includes(this.usuariosService.getCurrentUserData().clavePerfil))
                {
                  this.accountService.logout();
                }
                else{
                  window.history.back();
                }
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  abrirNotificaciones(){
    let obj1 = JSON.parse(sessionStorage.getItem('gu-user-obj')??'');

    sessionStorage.setItem('usrNot',JSON.stringify(obj1));
    sessionStorage.setItem('usrPerfNot',"socio");
    this.router.navigate(['/admin/notificaciones']);
  }

}
