import { FormGroup, ValidationErrors } from "@angular/forms";

export interface Indicator {
  icon: string;
  title: string;
}

const profileTypes: Record<string, string> = {
  cvdp: 'cvdps',
  academia: 'academicos',
  socio: 'socios',
  cvdp_admin: 'cvdps',
};

const indicators: Record<string, Indicator> = {
  numeroRetosActivos: {
    icon: 'dash_foco',
    title: 'Retos activos',
  },
  numeroSolicitudesRegistradas: {  //socio
    icon: 'dash_doc',
    title: 'Retos donde quiero participar',
  },
  numeroSFBuscandoVinculacion: { //acad
    icon: 'dash_usr',
    title: 'Mis búsquedas de Socios Formadores',
  },
  numeroSolicitudesSFPorVincular: {  //acad
    icon: 'dash_izq',
    title: 'Socios Formadores que se quieren vincular a mis retos',
  },
  numeroSolicitudesSFPotenciales: {
    icon: 'dash_foco',
    title: 'Solicitudes de Socios Formadores potenciales',
  },
  numeroSFSolicitandoReto: {
    icon: 'dash_izq',
    title: 'Socios Formadores solicitando un reto',
  },
  numeroAcademicosSolicitandoSF: {
    icon: 'dash_usr',
    title: 'Académicos solicitando un Socio Formador',
  },
  numeroSolicitudesProblemasSinReto: {
    icon: 'dash_doc',
    title: 'Solicitudes de problemas que no encontraron reto',
  },
};

export default class Utils {
  static extractValueWeeks = (weeks: string) => {
    const weeksArray = weeks.split(' ');
    return weeksArray?.[0] ?? 0;
  };

  static getTypeUserProfile = (type: string) => {
    return profileTypes[type];
  };

  static getIndicatorData = (type: string) => {
    return indicators[type] ?? null;
  };

  static cutLongText = (text: string) => {
    text = text??'';
    return text.length > 100 ? text.slice(0, 100).concat(' ...') : text;
  };

  static cutLongTextByXChars = (text: string, chars: number) => {
    text = text??'';
    return text.length > chars ? text.slice(0, chars).concat(' ...') : text;
  };

  static isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }

    if (str.trim() === '') {
      return false;
    }

    return !Number.isNaN(Number(str));
  }


  static getFormValidationErrors(formulario:FormGroup) {
    let totalErrors = 0;

    Object.keys(formulario.controls).forEach(key => {
      const controlErrors: ValidationErrors|null = formulario.get(key)?.errors ?? null;
      if (controlErrors != null) {
         totalErrors++;
         Object.keys(controlErrors).forEach(keyError => {
           console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
      }
    });

    console.log('Number of errors: ' ,totalErrors);
  }

  static getRouteFromProfile(clavePerfil: string){
    if (clavePerfil == 'academia')
        clavePerfil = 'acad';
    if(clavePerfil == 'cvdp_admin')
        clavePerfil = 'cvdp';
    if(clavePerfil == 'socio')
      clavePerfil = 'sf';
    return clavePerfil;
  }

  static processErrorResponse(err: any, msg: string = '') {
    if(err.status != 404){
      console.log(msg, err);
    }
  }

  static ListToString(miLista: any[], clave: string): string {
    if(!miLista)
      return "";
    if(miLista.length == 0)
      return "";

    return miLista.map((x) => x[clave]).join(", ");
}
}
