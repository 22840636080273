import { Clipboard } from "@angular/cdk/clipboard";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ICampus,
  IEscuela,
  IPeriodoSemanal,
  IPeriodoSemestral,
  IRegion,
  ISemana,
  ISemestre,
} from "@shared/Interface/ICatalogos.interface";
import { IReto } from "@shared/Interface/IReto.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom, tap } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-banco-retos",
  templateUrl: "./banco-retos.component.html",
  styleUrls: ["./banco-retos.component.css"],
})
export class BancoRetosComponent implements OnInit {
  titleBancoRetos = {
    title: "Banco de Retos",

  };
  textDataBotton = {
    title: "¿Tu problemática no se acopla a los retos mostrados?",
    subtitle: "Dinos más detalles sobre tu problemática y qué apoyo buscas.",
    buttons: [
      {
        title: "No encontre reto",
        type: "blue",
      },
      {
        title: "Ver banco retos",
        type: "yell",
        extraClass: "",
      },
    ],
  };
  paginationObj: any = [];
  retosObj: any = [];

  escuelasData: IEscuela[];
  programasAcademicosData: any[] = [];

  semanasData: any[];
  regionsData: IRegion[];
  campusData: ICampus[];
  periodoSemanalData: IPeriodoSemanal[];
  periodoSemestralData: IPeriodoSemestral[];
  semestreData: ISemestre[];
  clavePerfil: string;

  filter: any = {
    query: "",
    escuela: 0,
    periodoSemestral: 0,
    semanas: 0,
    programa: 0,
    semestre: 0,
    periodoSemanal: 0
  };

  localdbretos: any[] = [];

  constructor(
    private retosService: RetosService,
    private catalogosService: CatalogosService,
    private changeDetector: ChangeDetectorRef,
    private usuarioService: UsuariosService,
    private router: Router,
    private inicioService: InicioService,
    private route: ActivatedRoute,
    private clipboard: Clipboard
  ) {}

  // Se agrega función compatible con el buscador MainLayoutBuscador
  buscadorPress(keyWord: any) {
    // Aquí iría lo que el padre tenga que hacer

    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');

    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      this.filter.query = keyWord;
    } else {
      this.filter.query = "";
    }
    this.getRetos();
  }

  async getRetos() {
    this.paginationObj = [];

    this.chekfilters();
    let tmp = localStorage.getItem('localdb');
    if (tmp) {
      this.localdbretos = JSON.parse(tmp);
      if(this.localdbretos){
        this.paginationObj = this.localdbretos;
      }
    }
    else{
      this.paginationObj = await lastValueFrom(this.retosService
      .getRetos_query(
        this.filter.query,
        this.filter.escuela,
        this.filter.periodoSemestral,
        this.filter.semanas,
        this.filter.programa,
        this.filter.semestre,
        this.filter.periodoSemanal
      ));

    }
    this.saveFilters();
    localStorage.setItem('localdb',JSON.stringify(this.paginationObj));
  }

  async ngOnInit() {
    this.revisarReto();

    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    this.getRetos();

    this.programasAcademicosData = [];

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelasData = resultado)))
      .subscribe();

    this.catalogosService
      .getPeriodoSemanal()
      .pipe(
        tap((resultado: IPeriodoSemanal[]) => (this.periodoSemanalData = resultado))
      )
      .subscribe();

    this.catalogosService
      .getPeriodoSemestral()
      .pipe(
        tap((resultado: IPeriodoSemestral[]) => (this.periodoSemestralData = resultado))
      )
      .subscribe();

    this.catalogosService
      .getSemestre()
      .pipe(tap((resultado: ISemestre[]) => (this.semestreData = resultado)))
      .subscribe();

    this.catalogosService
      .getSemanas()
      .pipe(tap((resultado: ISemana[]) => (this.semanasData = resultado)))
      .subscribe();
  }

  addFilter(type: string, id: number) {
    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    this.setFilter(type, id);
    this.getRetos();
  }

  setFilter(type: string, id: number) {
    this.filter[type] = id;
    if (type === "escuela") {
      this.programasAcademicosData = [];
      this.filter.programa = 0;
      this.catalogosService.getProgramasAcademicos(id).subscribe((resOK) => {
        this.programasAcademicosData = resOK;
      });
    }
  }


  cleanFilters() {
    this.programasAcademicosData = [];
    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    this.inicioService.changeFilterQuery("");

    this.filter = {
      query: "",
      escuela: 0,
      periodoSemestral: 0,
      semanas: 0,
      programa: 0,
      semestre: 0,
      periodoSemanal: 0
    };
    this.getRetos();
  }

  showPaginationData(objData: any) {
    if (objData.length) {
      this.retosObj = objData;
      this.changeDetector.detectChanges();
    }
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  quieroVincularme() {
    if (this.clavePerfil === "academia") {
      this.inicioService.changeMenuItem("vinculacionsociosformadores");
      this.router.navigate(["/vincula-socios-formadores"]);
    }
    if (this.clavePerfil === "socio") {
      this.router.navigate(["/vinculacion-reto"]);
    }
  }

  revisarReto() {
    let idReto = this.route.snapshot.paramMap.get("idRetoMaestro");
    if (idReto) {
      let validrfc = atob(idReto);

      this.verReto(Number(validrfc));
    }
  }

  verReto(idRetoMaestro: number) {
    sessionStorage.setItem('idRetoMaestro',idRetoMaestro.toString());
    localStorage.setItem('idRetoMaestro',idRetoMaestro.toString());
    // define el modo consulta
    localStorage.setItem("origenVinculacion", "banco");
    let url = `/socio/pagina-retos`;
    window.location.href = url;
    //this.router.navigate([url]);
  }

  saveFilters(){
    let filters = {
        query:this.filter.query,
        escuela:this.filter.escuela,
        periodoSemestral:this.filter.periodoSemestral,
        semanas:this.filter.semanas,
        programa:this.filter.programa,
        semestre:this.filter.semestre,
        periodoSemanal:this.filter.periodoSemanal
    };
    localStorage.setItem('localdbFilters',JSON.stringify(filters));
    this.inicioService.changeFilterQuery(this.filter.query);
  }
  chekfilters(){
    let tmp1 = localStorage.getItem('localdbFilters');
    let ret=false;
    if(tmp1){
      let tmp = JSON.parse(tmp1);

        this.filter.query = tmp.query;
        this.filter.escuela = tmp.escuela;
        this.setFilter('escuela', this.filter.escuela);
        this.filter.periodoSemestral = tmp.periodoSemestral;
        this.setFilter('periodo', this.filter.periodoSemestral);
        this.filter.semanas = tmp.semanas;
        this.setFilter('semanas', this.filter.semanas);
        this.filter.programa = tmp.programa;
        this.setFilter('programa', this.filter.programa);
        this.filter.semestre = tmp.semestre;
        this.setFilter('semestre', this.filter.semestre);
        this.filter.periodoSemanal = tmp.periodoSemanal;
        this.setFilter('periodoSemanal', this.filter.periodoSemanal);

        ret = true;
    }
    return ret;
  }


}
