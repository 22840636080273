import { environment } from 'environments/environment';

export const baseUrl = environment.apiUrl;

export const moduleService: Record<string, string> = {
  DASHBOARD: 'dashboard',
  CATALOGOS: 'catalogos',
  PROBLEMATICAS: 'problematicas',
  SOLICITUDES: 'solicitudes',
  RETOS: 'retos',
  SOCIOS: 'socios',
  USUARIOS: 'usuarios',
  LOGS: 'logs',
  ADMINISTRACION: 'administracion'
};

export const subModuleService: Record<string, string> = {
  CVDP: 'cvdps',
  ACADEMICOS: 'academicos',
  AREAS: 'areas',
  CAMPUS: 'campus',
  ALCANCES: 'alcances',
  ESCUELAS: 'escuelas',
  REGIONES: 'regiones',
  PERIODOS: 'periodos',
  PERIODOS_SEMESTRAL: 'periodosSemestrales',
  PERIODOS_SEMANAL: 'periodosSemanales',
  CONTACTOS: 'contactos',
  VINCULACIONES: 'vinculaciones',
  SOLICITUDES_SOCIOS: 'solicitudesSocios',
  INDICADORES: 'indicadores',
  SOLICITUDES_RETOS: 'solicitudesRetos',
  SOLICITUDES_ACADEMICOS: 'solicitudesAcademicos',
  SOLICITUDES_SIN_RETO: 'solicitudesSinReto',
  SOLICITUDES_SOCIO_FORMADOR: 'solicitudesSocioFormador',
  PROGRAMAS_ACADEMICOS: 'programasAcademicos',
  SECTORES: 'sectores',
  INDUSTRIAS: 'industrias',
  UNIDADES_FORMATIVA: 'unidadesFormativas',
  IDIOMAS: 'idiomas',
  GIROS: 'giros',
  SEMANAS: 'semanas',
  RETOS_MAESTROS: 'retosMaestros',
  TAMANIO_EMPRESA: 'tamanosEmpresa',
  PRESENCIA: 'presencias',
  ACTIVACIONES: 'activaciones',
  RETOS_MATCH:'match',
  AUTOREGISTRO: 'autoregistro',
  CONVENIOS: 'convenios',
};
