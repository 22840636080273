import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { IUser } from '@shared/Interface/user';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { IGiros } from '@shared/Interface/IGiros';
import { INoEncontreSF } from '@shared/Interface/INoEncontreSF';
import { SolicitudesService } from '@shared/service/Solicitudes/solicitudes.service';
import Swal from 'sweetalert2';
import { RetosService } from '@shared/service/Retos/retos.service';
import { IRetoMaestro } from '@shared/Interface/IReto.interface';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-sf-no-encontre-sf',
  templateUrl: './sf-no-encontre-sf.component.html',
  styleUrls: ['./sf-no-encontre-sf.component.css']
})
export class SfNoEncontreSfComponent implements OnInit {
  lang: string = 'es';
  public enviarForm: FormGroup;
  public retos: IRetoMaestro[];
  public giros: IGiros[];
  public girosRequest: string[] = [];
  public NoEncontreSF: INoEncontreSF;
  public disabled: boolean = true;
  private _prevSelected: any;
  public palabras = ['Empresa familiar', 'Empresa que cotiza en la bolsa', 'ONG', 'Valor Agregado al Mercado']
  usuario!: string;
  isLogged: boolean = false;
  userProfile!: string;
  dataUser!: IUser;
  MatchObj: any;
  idRetoMaestro:any;

  constructor(
    public formBuilder: FormBuilder,
    private usuarioService: UsuariosService,
    private sociosService: SociosFormadoresService,
    private catalogosService: CatalogosService,
    private solicitudService: SolicitudesService,
    private retosService: RetosService,
    private router: Router
  ) {

    this.enviarForm = this.formBuilder.group({
      idTipoSolicitud: [3],
      descripcionSolicitud: [],
      idSocioFormador: [0],
      idRetoMaestro: [],
      idCVDP: [],
      idAcademia: [],
      idProblematica: [0],
      caracteristicas_sf: ['', [Validators.required]],
      bienes_produccion: [false],
      palabras_clave: [],
      giros: []

    })
  }


  async ngOnInit(): Promise<void> {
    let tmp =  localStorage.getItem('sociosMatch');
    this.MatchObj = JSON.stringify(tmp);

    this.lang = localStorage.getItem('lang') ?? 'es';
    let resultado1 = this.usuarioService.getCurrentUserData();
    this.dataUser = resultado1;

    this.idRetoMaestro = localStorage.getItem('idReto');


    this.getReto();
  }

  async getReto() {
    try {
      let idEscuela = Number(localStorage.getItem('idEscuela') ?? 0);
      this.retos = await lastValueFrom(this.retosService.getRetosMaestros(idEscuela));

      if (this.retos != null && this.retos != undefined && this.retos.length > 0) {
        this.enviarForm.get('idRetoMaestro')?.patchValue(this.idRetoMaestro);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error al obtener el detalle de un reto por su ID ',
        title:'Error'

     })
         }
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }


  async guardar() {
    if (!this.palabras) {
      Swal.fire({
        icon: 'info',
        text: 'Por favor captura la informaciòn obligatoria ',
        title:'Error'
     })
    } else {

      if (this.enviarForm.valid) {
        const descripcion = 'Características: ' + this.enviarForm.value.caracteristicas_sf; // + ', Giros: ' + this.girosRequest.toString() + ', Palabras: ' + this.palabras.toString()
        this.NoEncontreSF = {
          idTipoSolicitud: 3,
          descripcionSolicitud: descripcion,
          idSocioFormador: 0,
          idCVDP: 0,
          idAcademia: this.dataUser.idUsuario,
          idProblematica: 0,
          idRetoMaestro: this.enviarForm.value.idRetoMaestro,
          idCampus: this.dataUser.idCampus
        }
        try {
          let resultado = await lastValueFrom(this.solicitudService.postSolicitud(this.NoEncontreSF));
          Swal.fire({
            icon: 'success',
            text: 'Solicitud enviada correctamente',
            title:'Exito'

         }).then(async (result) => {

          let idLogBusquedaSocio = sessionStorage.getItem('idLogBusquedaSocio') ?? 0;

          await lastValueFrom(this.solicitudService.putSolicitud({
            idSolicitud: resultado.idSolicitud,
            idLogBusquedaSocio: Number(idLogBusquedaSocio),
            idUsuario: this.dataUser.idUsuario,
          }));

          localStorage.removeItem('idEscuela');
          localStorage.removeItem('idReto');

          this.router.navigateByUrl('/dashboard');
         }

         )
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'Error al enviar solicitud ',
            title:'Error'
         })
        }


      } else {
        Swal.fire({
          icon: 'info',
          text: 'Por favor captura la información obligatoria ',
          title:'Error'
       })
      }
    }

  }

  get m() {

    return this.enviarForm.controls;
  }


}
