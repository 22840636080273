<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div>
  <div class="sec_ad">
    <app-title-show-more [showMoreObject]="title"></app-title-show-more>

    <div id="sec_tab" class="d-flex flex-md-nowrap flex-wrap tabs-3">
      <div class="flex-fill">
        <div class="d-flex justify-content-end">
          <div class="btn-group order-by">
            <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
              data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
              {{ textOrdenarPor | translate }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="listado">
              <li><a (click)="ordenarLista('nombreReto')" class="dropdown-item" [class.active]="valorOrden== 1 ">{{
                  "retos_busqueda_sf.orden_A_Z" | translate }}</a></li>
              <li><a (click)="ordenarLista('SocioFormador')" class="dropdown-item " [class.active]="valorOrden== 2 ">{{
                  "retos_busqueda_sf.orden_socio_formador" | translate }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div id="c_sl_pa" class="sec_cnt tipo-10">
      <div class="d-none d-md-block">
        <div class="cont_tit d-flex">
          <div class="flex-fill col-1">{{ "retos_busqueda_sf.header_fecha" | translate }}</div>
          <div class="flex-fill col-3">{{ "retos_busqueda_sf.header_nombre_reto" | translate }}</div>
          <div class="flex-fill col-3">{{ "retos_busqueda_sf.header_socio_formador" | translate }}</div>
          <div class="flex-fill col-1">{{ "retos_busqueda_sf.header_periodo_semestral" | translate }}</div>
          <div class="flex-fill col-1">{{ "retos_busqueda_sf.header_periodo_semanal" | translate }}</div>
          <div class="flex-fill col-1">{{ "retos_busqueda_sf.header_estatus" | translate }}</div>
          <div class="flex-fill col-1">{{ "retos_busqueda_sf.header_acciones" | translate }}</div>
        </div>

        <div class="roundedsquare cont_fl d-flex align-items-center"
          *ngFor="let solicitud of solicitudesSFActualesList">

          <div class="flex-fill col-1">{{ solicitud.fechaRegistro | date:'dd/MMM/yy' }}</div>

          <div class="flex-fill col-3">
            <a [routerLink]="[]"
              (click)="verSolicitud(solicitud.idRetoMaestro, solicitud.idAcademico,  solicitud.idSolicitud) ">
              <div class="lmt_text">{{ solicitud.reto }}</div>
            </a>
            <span>{{ solicitud.escuela }} </span>
          </div>

          <div class="flex-fill col-3">
            <div class="lmt_text">{{ solicitud.idSocioFormador == 0 ? 'No se encontró Socio Formador' : solicitud.nombreSocioFormador }}</div>
            <div class="lmt_text">{{ solicitud.rfc }}</div>
          </div>

          <div class="flex-fill col-1 d-flex align-items-center">
            {{ solicitud.periodoSemestral }}
          </div>

          <div class="flex-fill col-1 d-flex align-items-center">
            {{ solicitud.periodoSemanal }}
          </div>


          <div class="flex-fill col-1" *ngIf="['registrado'].includes(solicitud.claveEstatus)">
            <img class="tam" alt="{{ solicitud.estatus }}" src="assets/svg/ev_{{ solicitud.claveEstatus}}.svg">
          </div>

          <div class="flex-fill col-1" *ngIf="!['registrado','propuesta'].includes(solicitud.claveEstatus)">
            <img class="tam" alt="{{ solicitud.estatus }}" src="assets/img/status_{{ solicitud.claveEstatus}}.png">
          </div>

          <div class="flex-fill col-1" *ngIf="['propuesta'].includes(solicitud.claveEstatus)">
            <span> {{solicitud.estatus }}</span>
          </div>

          <div class="flex-fill col-1">
            <app-button (callbackAction)="verSolicitud(solicitud.idRetoMaestro, solicitud.idAcademico,  solicitud.idSolicitud)">Ver</app-button>
          </div>
        </div>

        <div *ngIf="solicitudesSFActualesList.length === 0">
          <app-sin-datos-table></app-sin-datos-table>
        </div>

      </div>
    </div>

    <div class="d-block d-md-none">
      <div class="accordion" id="ac_2">
        <div class="accordion-item" *ngFor="let solicitud of solicitudesSFActualesMovilList">
          <h2 class="accordion-header">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
              [attr.data-bs-target]="'#fl_1'+solicitud.idSolicitud" aria-expanded="true">
              {{ solicitud.nombreContacto }}
            </button>
          </h2>
          <div id="fl_1{{solicitud.idSolicitud}}" class="accordion-collapse collapse show" data-bs-parent="#ac_2">
            <div class="accordion-body">
              <div><strong>{{ "sf_solicitando_reto.header_fecha" | translate }}</strong><br />{{ solicitud.fechaRegistro
                | date: 'dd/MMM/yy'}}</div>
              <div><strong>{{ "sf_solicitando_reto.header_socio_formador" | translate }}</strong><br /><a href="">{{
                  solicitud.reto }}</a><br /><span>{{ solicitud.campus }} - {{ solicitud.industria }}</span></div>
              <div><strong>{{ "sf_solicitando_reto.header_persona_contacto" | translate }}</strong><br /></div>
              <div class="flex-fill d-flex align-items-center"><img alt="Socio Formador" class="img_usr"
                  src="assets/img/avatar_sin.jpg">
                <div>
                  <div>{{ solicitud.nombreSocioFormador }}</div>
                </div>
              </div>
              <div><strong>{{ "retos_busqueda_sf.header_estatus" | translate }}</strong><br /></div>
              <div><img class="tam" alt="{{ solicitud.estatus }}" src="assets/img/status_{{ solicitud.claveEstatus }}.png"></div>
            </div>
          </div>
        </div>

      </div>
      <br />
      <div class="d-flex justify-content-center" *ngIf="paginaActualMasResultados < totalPaginas">
        <button [disabled]="paginaActualMasResultados >= totalPaginas" class="mb_mas"
          (click)="obtenerMasResultados()"><img alt="Más registros" class="tam_mas"
            src="assets/img/icon_mas_bullet.svg"> {{ "retos_busqueda_sf.cargar_resultados" | translate }}</button>
      </div>

      <div class="mt-2 d-flex justify-content-center"
        *ngIf="paginaActualMasResultados == totalPaginas && solicitudesSFActualesMovilList.length <= registrosPagina && solicitudesSFAllList.length > registrosPagina">
        <button class="mb_mas" (click)="obtenerMasResultados()"><img class="tam_mas" alt="Anterior"
            src="assets/img/icon_mas_bullet.svg"> {{ 'paginador.resultados_anteriores' | translate }} </button>
      </div>

      <div *ngIf="solicitudesSFActualesMovilList.length === 0">
        <app-sin-datos-table></app-sin-datos-table>
      </div>

    </div>

  </div>

  <nav id="pagination" class="d-none d-md-block mt-4" *ngIf="totalPaginas > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="paginaActual==1">
        <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual - 1)">{{ "paginador.anterior" | translate }}
          &nbsp;<img alt="Anterior" class="iCn" src="assets/img/icon pag_ant.svg"></a>
      </li>
      <li class="page-item" *ngFor="let num of pages" [class.active]="paginaActual==num">
        <a class="page-link" (click)="obtenerRegistrosActuales(num)">{{num}}</a>
      </li>
      <li class="page-item" [class.disabled]="paginaActual >=totalPaginas">
        <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual + 1)"><img class="iCn" alt="Siguiente"
            src="assets/img/icon pag_sig.svg">&nbsp; {{ "paginador.siguiente" |
          translate }}</a>
      </li>
    </ul>
    <div class="text-center">{{ "paginador.total_paginador" | translate: {paginaActual: paginaActual, totalPaginas:
      totalPaginas} }}</div>
  </nav>

</div>
