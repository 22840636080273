import { Clipboard } from "@angular/cdk/clipboard";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ICampus } from "@shared/Interface/ICatalogos.interface";
import { IEstadosResponse } from "@shared/Interface/IEstadosResponse";
import { IIndustriaResponse } from "@shared/Interface/IIndustriaResponse";
import { IsectorResponse } from "@shared/Interface/ISectorResponse";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { ITamanoEmpresaResponse } from "@shared/Interface/ITamanoEmpresaResponse";
import Utils from "@shared/helpers/utils";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, tap } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-academico-banco-sf",
  templateUrl: "./academia-banco-socio-formador.component.html",
  styleUrls: ["./academia-banco-socio-formador.component.css"],
})
export class AcademicoBancoSFComponent implements OnInit {
  sidebarExpanded = false;
  lang: string = "es";
  bancoSocioFormadorList: any[] = [];

  clavePerfil: string;
  BDSociosFormadores: ISocioFormadorResponse[] = [];
  socioFormadorList: ISocioFormadorResponse[] = [];
  socioFormadorActualesList: ISocioFormadorResponse[] = [];
  socioFormadorActualesMovilList: ISocioFormadorResponse[] = [];

  estadosList: IEstadosResponse[] = [];
  tamanosEmpresaList: ITamanoEmpresaResponse[] = [];
  industriaList: IIndustriaResponse[] = [];
  sectorLista: IsectorResponse[] = [];
  estatusList: any[] = [
    { idEstatus: 1, estatus: "activos" },
    { idEstatus: 2, estatus: "inactivos" },
    { idEstatus: 3, estatus: "todos" },
  ];
  campusList: ICampus[];

  /** Regsitros por página  */
  registrosPagina: number = 20;
  idCampusNacional: number = 53;

  paginaActual: number = 1;
  paginaActualMasResultados: number = 1;
  totalPaginas: number = 0;
  pages: number[] = [];

  selectEstado: string = "";
  selectIndustria: number = 0;
  selectSector: number = 0;
  selectTamano: number = 0;
  selectEstatus: string = "";
  selectCampus: number = 0;

  palabraBusqueda: string = "";
  textEstado: string = "";
  textIndustria: string = "";
  textSector: string = "";
  textTamano: string = "";
  textEstatus: string = "";
  textCampus: string = "";

  MostrarBotonDetalle: boolean = true;
  MostrarBotonInactivar: boolean = false;
  MostrarBotonAgregarSocioFormador: boolean = false;
  MostrarBotonEditar: boolean = false;
  MostrarEvaluacion: boolean = false;

  localdbsf: ISocioFormadorResponse[] = [];

  constructor(
    private socioFormadorService: SociosFormadoresService,
    private catalogoService: CatalogosService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private usuarioService: UsuariosService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private inicioService: InicioService
  ) {}

  async revisarSF() {
    let rfc = this.route.snapshot.paramMap.get("rfc");
    if (rfc) {
      let validrfc = atob(rfc);
      let sf: ISocioFormadorResponse = <ISocioFormadorResponse>(
        await lastValueFrom(
          this.socioFormadorService.buscarSocioPorRFC(validrfc)
        )
      );
      if (sf) {
        this.verSocioFormador(sf.idSocioFormador);
      } else {
        this.verSocioFormador(Number(validrfc));
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") ?? "es";

    this.revisarSF();

    await lastValueFrom(
      this.translate.get("academia_banco_socio_formador.orden_sector")
    );
    this.textSector = this.translate.instant(
      "academia_banco_socio_formador.orden_sector"
    );
    this.textEstado = this.translate.instant(
      "academia_banco_socio_formador.orden_estado"
    );
    this.textIndustria = this.translate.instant(
      "academia_banco_socio_formador.orden_industria"
    );
    this.textTamano = this.translate.instant(
      "academia_banco_socio_formador.orden_tamano"
    );
    this.textCampus = this.translate.instant(
      "academia_banco_socio_formador.orden_campus"
    );


    await this.getEstados();
    await this.getIndustria();
    await this.getTamanosEmpresa();
    await this.getSector();
    await this.getCampus();

    await this.inicializarTablas();

    let userData = this.usuarioService.getCurrentUserData();
    // INC banco
    // if(userData.clavePerfil === 'socio'){
    //   this.MostrarBotonDetalle = false;
    // }
    this.clavePerfil = userData.clavePerfil;

    if (["cvdp_admin", "cvdp", "academia"].includes(userData.clavePerfil)) {
      this.MostrarBotonInactivar = false;
      this.MostrarBotonAgregarSocioFormador = true;
      this.MostrarBotonEditar = true;
    }
    if (["cvdp_admin", "cvdp"].includes(userData.clavePerfil)) {
      this.MostrarBotonInactivar = true;
      this.MostrarBotonAgregarSocioFormador = true;
      this.MostrarBotonEditar = true;
    }
    if (this.clavePerfil != "socio") {
      this.MostrarEvaluacion = true;
    }
  }

  async inicializarTablas() {
    this.chekfilters();
    let tmp = localStorage.getItem("localdb");
    if (tmp) {
      this.localdbsf = JSON.parse(tmp);
      if (this.localdbsf) {
        this.BDSociosFormadores = this.localdbsf;
        this.socioFormadorList = this.localdbsf;
      }
    } else {
      await this.getSociosFormadores(
        this.palabraBusqueda,
        this.selectSector,
        this.selectIndustria,
        this.selectEstado,
        this.selectTamano,
        this.selectEstatus,
        this.selectCampus
      );
    }
  }

  changeLang(lang: string) {
    this.lang = lang;

    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  async buscadorPress(keyWord: any) {
    // Aquí iría lo que el padre tenga que hacer
    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      this.palabraBusqueda = keyWord;
    } else {
      this.palabraBusqueda = "";
    }

    await this.inicializarTablas();
  }

  async getEstados() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.catalogoService.getEstados())
      );
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.estadosList = resultado;
      }
    } catch (error) {
      console.error("Error al obtener todos los estados ", error);
    }
  }

  async getTamanosEmpresa() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.catalogoService.getTamanosEmpresa())
      );
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.tamanosEmpresaList = resultado;
      }
    } catch (error) {
      console.error("Error al obtener todos los tamaños de empresa ", error);
    }
  }

  async getIndustria() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.catalogoService.getIndustrias())
      );
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.industriaList = resultado;
      }
    } catch (error) {
      console.error("Error al obtener todos las industrias ", error);
    }
  }

  async getSector() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.catalogoService.getSectores())
      );
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.sectorLista = resultado;
      }
    } catch (error) {
      console.error("Error al obtener todos los sectores ", error);
    }
  }

  async getCampus() {
    try {
      let resultado = await lastValueFrom(this.catalogoService.getCampus());
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.campusList = resultado.filter(
          (x) => x.idCampus != this.idCampusNacional
        );
      }
    } catch (error) {
      console.error("Error al obtener todos los campus ", error);
    }
  }

  async getSociosFormadores(
    keySearch: string,
    sectorId: number,
    industriaId: number,
    claveEstado: string,
    tamanoId: number,
    estatus: string,
    campus: number
  ) {
    try {
      this.socioFormadorList = [];
      this.socioFormadorActualesList = [];
      this.socioFormadorActualesMovilList = [];
      this.BDSociosFormadores = [];

      // Verificamos si el valor es "todas", se manda 0 en industria
      if (industriaId == 21) {
        industriaId = 0;
      }

      let userData = this.usuarioService.getCurrentUserData() || {
        clavePerfil: "socio",
      };
      //let estatus = "";
      if (["cvdp", "cvdp_admin"].includes(userData.clavePerfil)) {
        estatus = "todos";
      }

      let validarConvenio: boolean = false;
      // al seleccional campus, validar el convenio
      // TASK: Se quita temporalmente, hasta que revisen los convenios
      // if(campus != 0){
      //   validarConvenio = true;
      // }



      let resultado = <any>(
        await lastValueFrom(
          this.socioFormadorService.getAllSociosFormadores(
            keySearch,
            sectorId,
            industriaId,
            claveEstado,
            tamanoId,
            estatus,
            campus,
            validarConvenio
          )
        )
      );

      if (resultado != null && resultado != undefined) {
        if (this.BDSociosFormadores.length == 0) {
          // Se guarda caché la primera vez para buscar por rfc
          this.BDSociosFormadores = [...resultado];
        }

        this.socioFormadorList = resultado.map((x) => {
          return {
            ...x,
            empresaShort: Utils.cutLongTextByXChars(x.empresa, 150),
          }
        });

        this.applySort();
      }
      this.saveFilters();
      localStorage.setItem('localdb',JSON.stringify(this.socioFormadorList));
    } catch (error) {
      localStorage.removeItem('localdb');
      localStorage.removeItem('localdbFilters');
      console.error(
        "Error al obtener todos los Socio Formador por filtros",
        error
      );

      this.socioFormadorList = this.BDSociosFormadores.filter((x: any) =>
        x.rfc.includes(keySearch)
      ).map((x) => {
        return {
          ...x,
          empresaShort: Utils.cutLongTextByXChars(x.empresa, 150),
        };
      });
    }

  }

  applySort() {
    let sfSinLogo = this.socioFormadorList.filter((x) => { return x.urlLogo.search(environment.imageSFDefault) > 0});
    let sfConLogo = this.socioFormadorList.filter((x) => {return x.urlLogo.search(environment.imageSFDefault) < 0});
    sfSinLogo = sfSinLogo.sort((a, b) => {
      return b.idSocioFormador - a.idSocioFormador;
    });
    sfConLogo = sfConLogo.sort((a, b) => {
      return b.idSocioFormador - a.idSocioFormador;
    });
    this.socioFormadorList = [...sfConLogo, ...sfSinLogo];
  }

  showPaginationData(objData: any) {
    if (objData.length) {
      this.socioFormadorActualesList = objData;
      this.socioFormadorActualesMovilList = objData;
      this.changeDetector.detectChanges();
    }
  }

  async selectFilter(seccionFiltro: string, idValor: any) {
    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    try {
      this.setValueFilters(seccionFiltro,idValor);

      await this.inicializarTablas();
    } catch (error) {
      console.error("Error al seleccionar filtro ", error);
    }
  }
  setValueFilters(seccionFiltro: string, idValor: any){
    switch (seccionFiltro) {
      case "industria":{
        this.selectIndustria = Number(idValor);
        let industriaFilter = this.industriaList.filter(
          (i) => i.idIndustria === this.selectIndustria
        );
        this.textIndustria =
          industriaFilter.length > 0
            ? this.cortarcadenaLarga(industriaFilter[0].industria)
            : "";
        break;
      }
      case "estado": {
        this.selectEstado = idValor;
        let estadoFilter = this.estadosList.filter(
          (e) => e.claveEstado === this.selectEstado
        );
        this.textEstado =
          estadoFilter.length > 0
            ? this.cortarcadenaLarga(estadoFilter[0].estado)
            : "";
        break;
      }
      case "sector": {
        this.selectSector = Number(idValor);
        let sectorFilter = this.sectorLista.filter(
          (e) => e.idSector === this.selectSector
        );
        this.textSector =
          sectorFilter.length > 0
            ? this.cortarcadenaLarga(sectorFilter[0].sector)
            : "";
        break;
      }
      case "tamano_empresa": {
        this.selectTamano = Number(idValor);
        let tamanoFilter = this.tamanosEmpresaList.filter(
          (e) => e.idTamanoEmpresa === this.selectTamano
        );
        this.textTamano =
          tamanoFilter.length > 0
            ? this.cortarcadenaLarga(tamanoFilter[0].tamanoEmpresa)
            : "";
        break;
      }
      case "estatus": {
        this.selectEstatus = idValor;
        let estatusFilter = this.estatusList.filter(
          (e) => e.idEstatus === this.selectEstatus
        );
        this.textEstatus =
          estatusFilter.length > 0
            ? this.cortarcadenaLarga(estatusFilter[0].estatus)
            : "";
        break;
      }
      case "campus": {
        this.selectCampus = idValor;
        let campusFilter = this.campusList.filter(
          (e) => e.idCampus === this.selectCampus
        );
        this.textCampus =
          campusFilter.length > 0
            ? this.cortarcadenaLarga(campusFilter[0].campus)
            : "";
        break;
      }
      default:{
        console.log("No existe filtro para ", seccionFiltro);
        break;
      }
    }
  }

  cortarcadenaLarga(cadena: string): string {
    try {
      let cadenaCorta = "";
      if (cadena != null && cadena != undefined && cadena.length > 15) {
        cadenaCorta = cadena.slice(0, 15);
      } else {
        cadenaCorta = cadena;
      }
      return cadenaCorta;
    } catch (error) {
      console.error("Error al cortar la cadena ", error);
      return cadena;
    }
  }

  async restablecer() {
    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    this.inicioService.changeFilterQuery("");


    try {
      this.textSector = this.translate.instant(
        "academia_banco_socio_formador.orden_sector"
      );
      this.textEstado = this.translate.instant(
        "academia_banco_socio_formador.orden_estado"
      );
      this.textIndustria = this.translate.instant(
        "academia_banco_socio_formador.orden_industria"
      );
      this.textTamano = this.translate.instant(
        "academia_banco_socio_formador.orden_tamano"
      );
      this.textCampus = this.translate.instant(
        "academia_banco_socio_formador.orden_campus"
      );

      this.palabraBusqueda = "";
      this.selectEstado = "";
      this.selectIndustria = 0;
      this.selectSector = 0;
      this.selectTamano = 0;
      this.selectCampus = 0;

      await this.inicializarTablas();
    } catch (error) {
      console.error("Error al restablecer el filtro ", error);
    }
  }

  verSocioFormador(idSocioFormador: number) {
    localStorage.removeItem("idSocioFormador");
    localStorage.setItem("idSocioFormador", idSocioFormador.toString());
    localStorage.setItem("origenVinculacion", "banco");
    let url = `/socio-formador`;
    window.location.href = url;
    //this.router.navigate([url]);
  }

  inactivarSocioFormador(idSocioFormador: number) {
    Swal.fire({
      title: "¿Desea INACTIVAR el Socio Formador?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Inactivar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .cambiarEstatus({
            idSocioFormador: idSocioFormador,
            estatus: "inactivo",
          })
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Inactivar Socio Formador.",
                  "Inactivado.",
                  "success"
                );
                localStorage.removeItem("localdb");
                this.ngOnInit();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  activarSocioFormador(idSocioFormador: number) {
    Swal.fire({
      title: "¿Desea ACTIVAR el Socio Formador?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .cambiarEstatus({
            idSocioFormador: idSocioFormador,
            estatus: "activo",
          })
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire("Activar Socio Formador.", "activado.", "success");
                localStorage.removeItem("localdb");
                this.ngOnInit();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  editarSocioFormador(idSocioFormador: number) {
    sessionStorage.setItem("idSocioFormador_banco_b", "true");
    sessionStorage.setItem("idSocioFormador_banco", idSocioFormador.toString());
    this.inicioService.changeSubMenuItem('datosempresa');
    let rutaClave = Utils.getRouteFromProfile(this.usuarioService.getCurrentUserData().clavePerfil);
    this.router.navigate([`/${rutaClave}/registro-sf`], {
      relativeTo: this.route,
    });
  }

  agregarSocioFormador() {
    sessionStorage.setItem("idSocioFormador_banco_b", "true");
    sessionStorage.setItem("idSocioFormador_banco", "0");
    this.inicioService.changeSubMenuItem('datosempresa');

    let rutaClave = Utils.getRouteFromProfile(this.usuarioService.getCurrentUserData().clavePerfil);
    this.router.navigate([`/${rutaClave}/registro-sf`], {
      relativeTo: this.route,
    });
  }

  generarShareUrl(socioFormador) {
    Swal.fire({
      text: "¿Deseas copiar el link del Socio Formador?",
      confirmButtonText: "Si!, Copiar link",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        let id =
          socioFormador.rfc.length > 0
            ? socioFormador.rfc
            : socioFormador.idSocioFormador;
        let url = `${location.origin}/banco-socio-formador/${btoa(id)}`;
        this.clipboard.copy(url);
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons.fire({
          title: "¡Link copiado!",
          timer: 1500,
          confirmButtonText: "¡Link copiado!",
        });
      }
    });
  }

  copiado() {
    this.toastr.success("Link copiado!");
  }

  mostrarBotonEditar(idusuario) {
    if (this.usuarioService.getCurrentUserData().clavePerfil == "academia") {
      if (this.usuarioService.getCurrentUserData().idUsuario == idusuario) {
        return true;
      } else {
        return false;
      }
    }
    return this.MostrarBotonEditar;
  }
  mostrarHome(oSocioFormador, verLogo) {
    this.socioFormadorService
      .actualizarLogo({
        idSocioFormador: oSocioFormador.idSocioFormador,
        verLogoHome: verLogo,
      })
      .pipe(
        tap((resultado: any) => {
          if (resultado) {
            this.toastr.success("Logo actualizado correctamente");
            oSocioFormador.verLogoHome = verLogo;
          } else {
            this.toastr.error("Algo Salió mal!");
          }
        })
      )
      .subscribe();
  }

  saveFilters(){
    let filters = {
        palabraBusqueda:this.palabraBusqueda,
        selectSector:this.selectSector,
        selectIndustria:this.selectIndustria,
        selectEstado:this.selectEstado,
        selectTamano:this.selectTamano,
        selectEstatus:this.selectEstatus,
        selectCampu:this.selectCampus
    };
    localStorage.setItem('localdbFilters',JSON.stringify(filters));
    this.inicioService.changeFilterQuery(this.palabraBusqueda);

  }
  chekfilters(){
    let tmp1 = localStorage.getItem('localdbFilters');
    let ret=false;
    if(tmp1){
      let tmp = JSON.parse(tmp1);

        this.palabraBusqueda = tmp.palabraBusqueda;
        this.selectSector = tmp.selectSector;
        if (this.selectSector > 0)
           this.setValueFilters('sector', this.selectSector);
        this.selectIndustria = tmp.selectIndustria;
        if (this.selectIndustria > 0)
           this.setValueFilters('industria', this.selectIndustria);
        this.selectEstado = tmp.selectEstado;
        if (this.selectEstado.length > 0)
           this.setValueFilters('estado', this.selectEstado);
        this.selectTamano = tmp.selectTamano;
        if (this.selectTamano > 0)
           this.setValueFilters('tamano_empresa', this.selectTamano);
        this.selectEstatus = tmp.selectEstatus;
        if(Number(this.selectEstatus) > 0)
           this.setValueFilters('estatus', this.selectEstatus);
        this.selectCampus = tmp.selectCampu;
        if(this.selectCampus > 0)
           this.setValueFilters('campus', this.selectCampus);

        ret = true;
    }
    return ret;
  }

}


