<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-typeform idForm="C9XbqAYO" *ngIf="mostrarAceptarAcademico" ></app-typeform>

<div class="sec_ad">
  <div class="row">


    <div class="col-12">
      <app-title-show-more [showMoreObject]="titleSolicitud"></app-title-show-more>
    </div>


  </div>

  <app-btn-regresar></app-btn-regresar>

  <!-- Muestra el estatus de la vinculación -->
  <div class=" mt-3">

    <div class="col-12 mb-2 ms-2">
      <div class="col-12">
        <div class="subtit1col"><strong>Estatus de la solicitud</strong></div>
      </div>
    </div>

    <!-- solicitud recibida -->
    <div class="container-alert-registrado"
      *ngIf="solicitudData !== undefined && solicitudData.claveEstatus.trim() === 'registrado' ">
      <div>
        <div class="col-md-12 mt-2 mb-2">
          <img class="icono-success" alt="Alerta" src="assets/svg/download.svg" />
          <span class="">
            {{ lbl_status_registrado }}
          </span>
        </div>
      </div>
    </div>

    <!-- aprobada aceptada -->
    <div class="container-alert" *ngIf="solicitudData !== undefined &&
    ['aceptado','propuesta','aprobado'].includes(solicitudData.claveEstatus.trim())
    &&
         muestraAlerta">
      <div class="col-12">
        <div class="col-md-12 mt-2 mb-2">
          <img class="icono-success" alt="Alerta" src="assets/img/success.svg" /> <span class="textsuccess">
            {{ lbl_status_registrado }}
            <button type="button" class="btn btn_link btn_verde ms-2" (click)="verPropuesta()"
              *ngIf="['propuesta'].includes(solicitudData.claveEstatus.trim()) ">VER</button>
          </span>

        </div>
      </div>
    </div>

    <!-- rechazada noaceptada -->
    <div class="container-alert-rechazo"
      *ngIf="solicitudData !== undefined &&
        (solicitudData.claveEstatus.trim() === 'rechazado'|| solicitudData.claveEstatus.trim() === 'no-aceptado') && muestraAlerta ">
      <div class="row">
        <div class="col-md-12 mt-2 mb-2">
          <img class="icono-success" alt="Alerta" src="assets/img/icons8-close.svg" /> <span class="textrechazo">{{
            "solicitud_sf_potencial-rechazo.alerta_rechazo_solicitud" | translate }} {{ solicitudData.actualizadoPor
            }}</span>
        </div>
        <div class="textrechazo ms-5 "> Motivo: {{ solicitudData.motivoRechazo}}</div>
      </div>
    </div>

    <!-- Ver Comentarios -->
    <div class="col-12" *ngIf="!['socio'].includes(clavePerfil)">

      <mat-accordion>
        <mat-expansion-panel class="my-2 px-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

          <mat-expansion-panel-header class="my-4 ">
            <div class="">
              <span class="title-option">Ver comentarios </span><br />

            </div>
          </mat-expansion-panel-header>

          <div class="maxHeight">
            <div class="col-12" *ngFor="let com of comentariosData">
              <div>
                Fecha: {{com.fechaRegistro}}<br />
                Estatus: {{obtenerEstatusSeguimiento(com.claveEstatus)}}<br />
                Comentarios: {{ com.comentarios}}
              </div>
              <hr>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>

    <!-- Ver Historico de SF -->
    <div class="col-12" *ngIf="solicitudData.idTipoSolicitud == 3 && !['socio'].includes(clavePerfil)">

      <mat-accordion>
        <mat-expansion-panel class="my-2 px-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

          <mat-expansion-panel-header class="my-4 ">
            <div class="">
              <span class="title-option">Propuestas de SF </span><br />
            </div>
          </mat-expansion-panel-header>

          <div class="maxHeight">
            <div class="col-12" *ngFor="let com of propuestasData">
              <div>
                Fecha: {{com.fechaRegistro}}<br />
                Socio Formador: {{ com.rfc }} {{ com.nombreSocioFormador }}<br />
                Comentarios: {{ com.comentarios }}
              </div>
              <hr>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>

    <!-- Formulario de Comentarios -->
    <div class="col-12" *ngIf="!['socio','academia'].includes(clavePerfil)">
      <div class="bkgatomic_alert_w">
        <form [formGroup]="estatusSolForm" (submit)="guardarComentarios()">
          <div class="row">
            <div class="col-6">
              <div class="col-12">
                <span *ngIf="!['rechazado','aprobado','propuesta'].includes(solicitudData.claveEstatus)"
                  class=""><strong>Selecciona el estatus de la solicitud</strong></span>
                <select *ngIf="!['rechazado','aprobado','propuesta'].includes(solicitudData.claveEstatus)"
                  class="form-select mb-4" name="status" id="status" formControlName="estatus">
                  <option value="revision">En revisión</option>
                  <option value="registrado">Recibido</option>
                </select>
              </div>

              <div class="col-12 ">
                <textarea style="border-radius: 5px; width: 100%;" formControlName="comentario" rows="4"
                  placeholder='Escribe un comentario sobre el estatus de la solicitud por ejemplo "Ya se contactó al profesor coordinador del reto", etc.'
                  required></textarea>
              </div>
            </div>
            <div class="col-6 justify-content-end d-flex">
              <div>
                <button class="btn yell_button_small_w">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>


  <!-- Academico y SF -->
  <div class=" mt-3">

    <div class="row mb-2 ms-2">
      <div class="col-5 p-0 d-flex align-items-end">
        <div class="subtit1col"><strong>Académico que busca Socio Formador</strong></div>
      </div>
      <div class="col-4 d-flex align-items-end">
        <div class="subtit1col"><strong>Socio Formador de interés:</strong></div>
      </div>
      <div class="col-3">
        <!-- Editar -->
        <div class="mb-1" *ngIf="['cvdp','cvdp_admin'].includes(clavePerfil)">
          <button class="yell_button" (click)="editar()"> <img alt="" src="assets/img/editar.svg" class="btn_editar">{{
            'academia_banco_socio_formador.editar_socio_formador' | translate }} Solicitud</button>
        </div>
      </div>
    </div>

    <div class="col-12 bkgatomic_alert_w">

      <div class="row">

        <div class="col-6 ">

          <div class="row">
            <div class="col-3 atomic_spc">
              <img class="img_avt_mn" alt="Logo"
                src="{{ profesorSolicita.urlFotoUSaurio??'assets/img/avatar_1.jpg' }}" />
            </div>
            <div class="col-9 atomic_spc">
              <div class="titsans_contact_b"><strong>{{ profesorSolicita.nombreUsuario }}</strong></div>
              <div class="subtgris">{{ profesorSolicita.usuario ?? '-' }}</div>
              <div class="subtgris">{{ profesorSolicita.rolAcademico ?? '-' }}</div>
              <div class="subtgris">{{ profesorSolicita.campus ?? '-' }}</div>
            </div>

          </div>
        </div>

        <div class="col-6 " *ngIf="!socioFormador">
          <div class="row" *featureFlag="'F2_5_HU69'">
            <div class="col-3 atomic_spc">
              <img class="img_avt_mn" alt="Socio Formador" *ngIf="socioFormador?.urlLogo"
                src="{{ socioFormador?.urlLogo  }}" />
            </div>
            <div class="col-9 atomic_spc">
              <div class="subtred ">Este académico no encontró un Socio Formador.</div>
              <div class="titsans_contact_b"><strong>Características del Socio Formador que busca: </strong></div>
              <div class="subtgris">{{ lbl_sf_descripcionSolicitud }}</div>
            </div>
          </div>

          <div class="row" *featureFlag="'F2_4_HU69'">
            <div class="col-3 atomic_spc" *ngIf="socioFormador?.urlLogo">
              <img class="img_avt_mn" alt="Socio Formador" src="{{ socioFormador?.urlLogo }}" />
            </div>
            <div class="col-9 atomic_spc">
              <div class="subtgris ">Este académico no encontró un Socio Formador para su reto.</div>
              <div class="titsans_contact_b"><strong>Características: </strong></div>
              <div class="subtgris">{{ lbl_sf_descripcionSolicitud }}</div>
            </div>
          </div>

        </div>

        <div class="col-6 " *ngIf="socioFormador">

          <div class="row">
            <div class="col-3 atomic_spc">
              <img class="img_avt_mn" alt="Socio Formador" *ngIf="socioFormador?.urlLogo"
                src="{{ socioFormador?.urlLogo }}" />
            </div>
            <div class="col-9 atomic_spc">
              <div class="titsans_contact_b"><a href="" [routerLink]="" class="linkSF"
                  (click)="mostrarDetalle()"><strong>{{ socioFormador?.nombreSocioFormador }}</strong></a></div>
              <div class="subtgris">{{ socioFormador.rfc ?? '-' }}</div>
              <div class="subtgris">{{ socioFormador.campus ?? '-' }}</div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

  <!-- Información sobre la vinculación -->
  <div class="row" *featureFlag="'F2_4_HU69'">
    <div class="subtit1col"><strong>Sobre la vinculación</strong></div>

    <div class="col bkgatomic_alert_w" *ngIf="!edit">
      <div class="row">
        <div class="col d-flex mb-4">
          <div class="title-option ">Periodo semestral:</div>
          <div class="ms-3">
            {{ lbl_periodo }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex mb-4">
          <div class="title-option">Periodo semanal:</div>
          <div class="ms-3">
          </div>&nbsp;{{ lbl_periodosSemanales_sol }}
        </div>
      </div>

    </div>


  </div>


  <div class="">
    <!-- Medio por el cual se realizó la búsqueda -->
    <div class="subtit1col mt-2 mb-1 ms-2">
      <strong>
        Medio por el cual se realizó la búsqueda del Socio Formador:
      </strong>
    </div>

    <div class="col bkgatomic_alert_w">

      <div class="row mb-4">
        <div class="title-option" >{{ lblMedioBusqueda }}</div>
      </div>

      <div class="row ms-2" *ngIf="!busquedaPorBancodeSocios && lbl_busqueda_acerca_sf !== '-'">
        <div class="col mb-4">
          <div class="title-option ">Acerca del Socio Formador requerido:</div>
          <div class="">
            {{ lbl_busqueda_acerca_sf }}
          </div>
        </div>
      </div>


      <div class="row ms-2 d-none" *ngIf="!busquedaPorBancodeSocios">
        <div class="col mb-4">
          <div class="title-option ">Características:</div>
          <div class="">
            {{ lbl_busqueda_areas }}
          </div>
        </div>
      </div>

      <div class="row ms-2 d-none" *ngIf="!busquedaPorBancodeSocios">
        <div class="col mb-4">
          <div class="title-option">Alcance de la problemática:</div>
          <div class="">
          </div>&nbsp;{{ lbl_busqueda_alcance }}
        </div>
      </div>

      <div class="row ms-2 d-none" *ngIf="!busquedaPorBancodeSocios">
        <div class="col mb-4">
          <div class="title-option">Industrias:</div>
          <div class="">
          </div>&nbsp;{{ lbl_busqueda_nombre }}
        </div>
      </div>

      <div class="row ms-2 d-none" *ngIf="!busquedaPorBancodeSocios">
        <div class="col mb-4">
          <div class="title-option">Sector:</div>
          <div class="">
          </div>&nbsp;{{ lbl_busqueda_contexto }}
        </div>
      </div>

      <div class="row ms-2 d-none" *ngIf="!busquedaPorBancodeSocios">
        <div class="col mb-4">
          <div class="title-option">Tamaño:</div>
          <div class="">
          </div>&nbsp;{{ lbl_busqueda_objetivos }}
        </div>
      </div>

      <div class="row ms-2 d-none" *ngIf="socioFormador && !busquedaPorBancodeSocios">
        <div class="col mb-4">
          <div class="title-option">Información del Académico:</div>
          <div class="">
          </div>&nbsp;{{ lbl_busqueda_academico_informacion }}
        </div>
      </div>



    </div>


    <!-- Reto a Vincular -->
    <!-- Información del reto -->
    <div class="">
      <div class="subtit1col mt-3 mb-1 ms-2"><strong>Reto a vincular</strong></div>

      <div class="col-12 my-3" *ngIf="edit">
        <form [formGroup]="infoRetoForm">
          <div class="col-md-12 my-2">
            <span class="question-text">Selecciona la Escuela <span class="requerido">*</span></span>

            <div class="requerido" *ngIf="infoRetoForm.get('idEscuela')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">
              <select class="form-select" id="idEscuela" aria-label="Default select example" formControlName="idEscuela"
                (change)="getUnidadFormativa($event)">
                <option selected>Selecciona</option>
                <option *ngFor="let escuela of escuelaData" value="{{ escuela.idEscuela }}">
                  {{ escuela.escuela }}
                </option>
              </select>

            </div>
          </div>

          <div class="col-md-12 my-2">
            <span class="question-text">Selecciona la Unidad Formativa <span class="requerido">*</span></span>

            <div class="requerido" *ngIf="infoRetoForm.get('idUnidadFormativa')?.errors?.['required']">
              Este campo es requerido.
            </div>

            <ng-select aria-label="Default " (change)="getRetoDetail($event)" formControlName="idUnidadFormativa">
              <ng-option *ngFor="let reto of unidadFormativaData" [value]="reto.idUnidadFormativa"
                selected="verifySelected(reto.idUnidadFormativa, 'idUnidadFormativa')">
                {{ reto.clave }} - {{ reto.unidadFormativa}}
              </ng-option>
            </ng-select>
          </div>
        </form>

      </div>

      <div class="col-12">
        <div class="bkgatomic_alert_w">

          <div class="col-12 mt-3">
            <div class="title-option">Unidad Formativa:</div>
            <div class="title-text">{{ retoInformacion.reto }}</div>
          </div>

          <div class="col mt-4">
            <div class="title-option">Escuela:</div>
            <div class="ms-2">
              {{ retoInformacion.escuela }}
            </div>
          </div>

          <div class="col mt-4">
            <div class="title-option">Programa académico:</div>
            <div class="ms-2">
              {{ lbl_programasAcademicos }}
            </div>
          </div>

          <div class="row">
            <div class="col-6 mt-4">
              <div class="title-option">Periodo Semestral:</div>
              <div class="ms-2">
                {{ lbl_periodosSemestrales }}
              </div>
            </div>

            <div class="col-6 mt-4">
              <div class="title-option">Periodo Semanal:</div>
              <div class="ms-2">
                {{ lbl_periodosSemanales }}
              </div>
            </div>
          </div>

          <!-- Iconos - Detalle del reto  -->
          <div class="row mt-5">
            <div class="col-md-12">
              <div class="ico_reto_container">
                <div class="ico_reto_container_local"></div>
                <div class="ico_reto_numsem"></div>
                <div class="ico_reto_container_inner_label">{{ lbl_alcance }}</div>
              </div>

              <div class="ico_reto_container">
                <div class="ico_reto_container_semestre"></div>
                <div class="ico_reto_numsem"></div>
                <div class="ico_reto_container_inner_label">{{ lbl_semestre }}</div>
              </div>

              <div class="ico_reto_container">
                <div class="ico_reto_container_calendario"></div>
                <div class="ico_reto_numsem">&nbsp;{{ lbl_semana_numero }}</div>
                <div class="ico_reto_container_inner_label">{{ lbl_semana }}</div>
              </div>

              <div class="ico_reto_container">
                <div class="ico_reto_container_lang"></div>
                <div class="ico_reto_numlang">{{ lbl_idioma_numero }}</div>
                <div class="ico_reto_container_inner_label">{{ lbl_idioma }}</div>
              </div>

              <div class="ico_reto_container" *ngIf="retoInformacion.sentidoHumano">
                <div class="ico_reto_container_SentidoHumano"></div>
                <div class="ico_reto_container_inner_label_SH">Reto con Sentido Humano</div>
              </div>
            </div>
          </div>

          <hr class="my-5" style="color: #BEDCFE;">

          <!-- Descripcion, objetivo entregable -->
          <div class="col-md-12">
            <div class="title-option mt-4">{{ "pagina_retos_detalles.descripcion" | translate }}</div>
            <div class="ms-2 ">{{ retoInformacion.descripcion }}</div>

            <div class="title-option mt-4">{{ "pagina_retos_detalles.objetivo" | translate }}</div>
            <div class="ms-2"> {{ retoInformacion.objetivo }} </div>

            <div class="title-option mt-4">{{ "pagina_retos_detalles.entregable" | translate }}</div>
            <div class="ms-2"> {{ retoInformacion.entregable }} </div>
          </div>

          <!-- Sector, tamano, industria, presencia, descripcion del SF -->
          <div class="col-12 mt-4">
            <div class="title-option " [innerHTML]="'pagina_retos_detalles.label_requisitos' | translate "></div>
            <div class="" [innerHTML]="'pagina_retos_detalles.label_requisitos_subtitulo' | translate"></div>

            <div class="row mt-4" *ngIf="retoInformacion.sectores">
              <div class="col-12"></div>
              <section class="col-md-12">
                <div>
                  <span class="imgcard">
                    <img src="/assets/img/icon_empresa.png" alt="Sector" class="imgcarrousel_reto" /></span>
                  <span class="txtcard ms-3" [innerHTML]="lbl_sf_sectores"></span><br />
                </div>
              </section>
            </div>


            <div class="row mt-4" *ngIf="retoInformacion.tamanos">
              <div class="col-12"></div>
              <section class="col-md-12">
                <div>
                  <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Tamaño"
                      class="imgcarrousel_reto" /></span>
                  <span class="txtcard ms-3" [innerHTML]="lbl_sf_tamanos"></span><br />
                </div>
              </section>
            </div>

            <div class="row mt-4" *ngIf="retoInformacion.industrias">
              <div class="col-12"></div>
              <section class="col-md-12">
                <div>
                  <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Industria"
                      class="imgcarrousel_reto" /></span>
                  <span class="txtcard ms-3" [innerHTML]="lbl_sf_industria"></span><br />
                </div>
              </section>
            </div>

            <div class="row mt-4" *ngIf="retoInformacion.presencias">
              <div class="col-12"></div>
              <section class="col-md-12">
                <div>
                  <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Presencia del Socio Formador"
                      class="imgcarrousel_reto" /></span>
                  <span class="txtcard ms-3" [innerHTML]="lbl_sf_presencias"></span><br />
                </div>
              </section>
            </div>

            <div class="row mt-4" *ngIf="lbl_sf_descripcion">
              <div class="title-option mt-4">{{ "pagina_retos_detalles.descripcion_socio_formador" | translate }}</div>
              <div class="ms-2"> {{ lbl_sf_descripcion }} </div>
            </div>

          </div>

        </div> <!-- fin -->
      </div>
    </div>

    <!-- Información sobre la vinculación -->
    <div class="" *featureFlag="'F2_5_HU69'">
      <div class="subtit1col ms-2"><strong>Sobre la vinculación</strong></div>

      <div class="col bkgatomic_alert_w" *ngIf="!edit">
        <div class="row">
          <div class="col d-flex mb-4">
            <div class="title-option ">Periodo semestral:</div>
            <div class="ms-3">
              {{ lbl_periodo }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex mb-4">
            <div class="title-option">Periodo semanal:</div>
            <div class="ms-3">
            </div>&nbsp;{{ lbl_periodosSemanales_sol }}
          </div>
        </div>
        <div class="row">
          <div class="col d-flex mb-4">
            <div class="title-option">Modalidad en la que se va a llevar cabo el reto:</div>
            <div class="ms-3">
            </div>&nbsp;{{ lbl_modalidad }}
          </div>
        </div>

      </div>

      <div class="col bkgatomic_alert_w1 mt-3" *ngIf="edit">
        <form [formGroup]="vinculaForm">
          <div class="row">
            <div class="col mb-4">
              <div class="title-option ">Selecciona el periodo semestral:</div>
              <div class="col-4">
                <ng-select placeholder="Seleciona una opción" formControlName="idPeriodo" [items]="periodosData"
                  bindLabel="periodo" bindValue="idPeriodo">
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col mb-4">
              <div class="title-option">Selecciona el o los periodo semanales:</div>
              <div class="">

                <span class="form-check form-check-inline my-2" *ngFor="let periodoSemana of periodoSemanalData">
                  <input class="form-check-input-check me-1" id="periodoSemena_{{periodoSemana.idPeriodoSemanal}}"
                    (change)="verificaCheckPeriodoSemanal($event)"
                    [checked]="verificaChecked(periodoSemana.idPeriodoSemanal)"
                    value="{{ periodoSemana.idPeriodoSemanal}}" type="checkbox" />
                  <label class="check-label me-3" for="periodoSemena_{{periodoSemana.idPeriodoSemanal}}"> {{
                    periodoSemana.periodoSemanal}}</label>
                </span>

              </div>
            </div>
          </div>



          <div class="row">
            <div class="col mb-4">
              <div class="title-option">Selecciona la Modalidad:</div>
              <div class="col-4">
                <ng-select placeholder="Selecciona una opción" formControlName="idModalidad" [items]="modalidadData"
                  bindValue="idModalidad" bindLabel="Modalidad"></ng-select>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>

    <!-- botonera 2.5 *featureFlag="'F2_5_HU69'" -->
    <div *ngIf="!socioFormador">
      <div class="row justify-content-center" *ngIf="muestraBotones">

        <div class="col-12 d-flex justify-content-center" *ngIf="edit">

          <button type="button" class="btn yell_button_small_w mx-2" (click)="guardarEdicion()">Guardar</button>
          <button type="button" class="btn blue_button_small mx-2" (click)="cancelarEdicion()">Cancelar</button>

        </div>

        <div class="col-md-3" *ngIf="!edit">
          <span class="#">
            <button class="green_button"
              *ngIf="clavePerfil =='socio' || clavePerfil == 'cvdp' || clavePerfil=='cvdp_admin' "
              (click)="handleAceptar2_5()">
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
                class="iCn replaced-svg">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #4c964d;
                    }
                  </style>
                </defs>
                <path class="cls-1"
                  d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                  transform="translate(-0.44 -2.65)"></path>
              </svg>
              Proponer un SF
            </button>
          </span>
        </div>

        <div class="col-md-3 " *ngIf="!edit && solicitudData.claveEstatus.trim() != 'rechazado' ">
          <span class="#"><button class="pink_button"
              *ngIf="clavePerfil =='socio' || clavePerfil == 'cvdp' || clavePerfil=='cvdp_admin'"
              (click)="handleRechazar()">
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
                class="iCn replaced-svg">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #ce0032;
                    }
                  </style>
                </defs>
                <path class="cls-1"
                  d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                  transform="translate(-1.56 -1.37)"></path>
              </svg>
              Rechazar
            </button></span><br />
        </div>

        <div class="col-md-3" *ngIf="!edit">
          <span class="#"><button class="blue_button" (click)="handleReasignar()">
              Reasignar
            </button>
          </span>
        </div>

      </div>
    </div>

    <!-- botonera 2.4 *featureFlag="'F2_4_HU69'" -->
    <div *ngIf="socioFormador">
      <div class="row justify-content-center" *ngIf="muestraBotones">

        <div class="col-12 d-flex justify-content-center " *ngIf="edit">
          <button type="button" class="btn yell_button_small_w mx-2" (click)="guardarEdicion()">Guardar</button>

          <button type="button" class="btn blue_button_small mx-2" (click)="cancelarEdicion()">Cancelar</button>
        </div>

        <!-- Perfil del socio-->
        <div class="d-flex justify-content-center">
          <div class="col-md-3 mx-2" *ngIf="!edit">
            <span class="#"><button class="green_button"
                *ngIf="['socio'].includes(clavePerfil)  && ['aprobado'].includes(solicitudData.claveEstatus)"
                (click)="enviarSolicitudActualizarEstatusSocio('aceptado')">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
                  class="iCn replaced-svg">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #4c964d;
                      }
                    </style>
                  </defs>
                  <path class="cls-1"
                    d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                    transform="translate(-0.44 -2.65)"></path>
                </svg>
                Aceptar
              </button></span>
          </div>

          <div class="col-md-3 mx-2" *ngIf="!edit && !['rechazado'].includes(solicitudData.claveEstatus)">
            <span class="#"><button class="pink_button"
                *ngIf="['socio'].includes(clavePerfil)"
                (click)="enviarSolicitudActualizarEstatusSocio('rechazado')">
                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
                  class="iCn replaced-svg">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #ce0032;
                      }
                    </style>
                  </defs>
                  <path class="cls-1"
                    d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                    transform="translate(-1.56 -1.37)"></path>
                </svg>
                Rechazar
              </button></span><br />
          </div>
        </div>
        <!-- Fin Perfil del socio -->

        <div class="col-md-3" *ngIf="!edit">
          <span class="#"><button class="green_button"
              *ngIf="['cvdp','cvdp_admin'].includes(clavePerfil)  && !['aprobado','aceptado'].includes(solicitudData.claveEstatus)"
              (click)="handleAceptar2_4()">
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
                class="iCn replaced-svg">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #4c964d;
                    }
                  </style>
                </defs>
                <path class="cls-1"
                  d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                  transform="translate(-0.44 -2.65)"></path>
              </svg>
              Aceptar
            </button></span>
        </div>

        <div class="col-md-3" *ngIf="!edit && !['rechazado'].includes(solicitudData.claveEstatus)">
          <span class="#"><button class="pink_button"
              *ngIf="['cvdp','cvdp_admin'].includes(clavePerfil)  "
              (click)="handleRechazar()">
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
                class="iCn replaced-svg">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #ce0032;
                    }
                  </style>
                </defs>
                <path class="cls-1"
                  d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                  transform="translate(-1.56 -1.37)"></path>
              </svg>
              Rechazar
            </button></span><br />
        </div>

        <div class="col-md-3" *ngIf="!edit && ['cvdp','cvdp_admin'].includes(clavePerfil)">
          <span class="#"><button class="blue_button" (click)="handleReasignar()">
              Reasignar
            </button>
          </span>
        </div>

      </div>
    </div>

    <div class="row aceptar_Asignar_Academico" *ngIf="mostrarAceptarAcademico">
      <div class="col mt-4">
        <div>
          <div class="title-option">Contacto del Socio Formador asignado:</div>
        </div>
        <div *ngIf="solicitudData !== undefined && solicitudData.claveEstatus !== 'revision'">
          {{ lblProfesorAsignado }}
        </div>
        <div
          *ngIf="solicitudData !== undefined && ['revision', 'registrado','propuesta'].includes(solicitudData.claveEstatus) ">

          <ng-select id="contactoSFAsignado" name="contactoSFAsignado" (change)="seleccionarContacto($event)"
            bindValue="idUsuario" bindLabel="nombreCompleto" [items]="contactosData"
            placeholder="Selecciona una opción ... "></ng-select>

        </div>
      </div>

      <div class="row mt-5">
        <app-button buttonType="yell" (callbackAction)="enviarSolicitud()">Enviar</app-button>
      </div>

    </div>

    <div class="row Re-Asignar_Academico" *ngIf="mostrarReasignar">
      <div class="col mt-4">
        <div>
          <div class="title-option">Campus asignado:</div>
        </div>

        <div *ngIf="solicitudData !== undefined && ['revision','registrado','propuesta','aprobado','rechazado'].includes(solicitudData.claveEstatus)">

          <ng-select id="campusReasginar" name="campusReasginar" (change)="seleccionarCampus($event)" bindLabel="campus"
            bindValue="idCampus" [items]="campusData" placeholder="Selecciona un campus ... "></ng-select>


        </div>
      </div>

      <div class="row mt-5">
        <app-button buttonType="yell" (callbackAction)="enviarSolicitud()">Enviar</app-button>
      </div>

    </div>

    <!-- Proponer SF -->
    <div class="modal fade" id="idModalResolver" tabindex="-1" aria-labelledby="ModalCorreoExistente"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content p-3">
          <div class="modal-header">
            <h5 class="modal-title-resolver" id="ModalCorreoExistente"><b> Envía una propuesta de Socio Formador al
                académico</b></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="" *ngIf="this.solicitudData.claveEstatus.trim()==='rechazado'">
              <div class="row d-flex jusitfy-content-center">
                <div class="col-3 atomic_spc">
                  <img class="img_avt_mn" alt="Socio Formador" *ngIf="propuestaSF?.urlLogo != ''"
                    src="{{ propuestaSF?.urlLogo }}" />
                </div>
                <div class="col-5 atomic_spc">
                  <div class="titsans_contact_b"><a href="" [routerLink]="" class="linkSF"
                      (click)="mostrarDetalle()"><strong>{{ propuestaSF?.nombreSocioFormador }}</strong></a></div>
                  <div class="subtgris">{{ propuestaSF?.rfc }}</div>
                </div>
                <div class="col-11 atomic_spc">
                  <div class="title-option">Comentarios:</div>
                  {{ propuestaSF?.comentarios }}
                </div>
                <hr>
              </div>

            </div>


            <form [formGroup]="proponerSFForm">
              <div class="row">
                <div class="col-12 my-2">
                  <label class="rcontainer my-3">
                    Selecciona a un Socio Formador del banco:
                  </label>
                  <ng-select [items]="sociosData" bindLabel="nombreSocioFormador" bindValue="idSocioFormador"
                    (change)="limpiarCampos('nombreSocioFormador')" formControlName="idSocioFormador"
                    placeholder="Selecciona..."></ng-select>
                </div>

                <div class="col-12 my-2">
                  <label class="rcontainer my-3">
                    O escribe el nombre de otro Socio Formador:
                  </label>
                  <input class="form-control" type="text" (change)="limpiarCampos('idSocioFormador')"
                    formControlName="nombreSocioFormador" placeholder="Nombre del Socio Formador">
                </div>

                <div class="col-12">
                  <div class="container-contacto-enlace">
                    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12 mt-4">
                      <span class="title-option">{{ "solicitud_sf_potencial-rechazo.comentarios_contacto" | translate
                        }}</span><span class="text-danger">*</span><br />
                      <textarea style="border-radius: 5px; width: 100%;" rows="7" formControlName="comentarios"
                        placeholder='Puedes agregar un comentario para especificar detalles sobre tu propuesta de Socio Formador.'
                        required></textarea>

                      <div class="requerido"
                        *ngIf="this.proponerSFForm.get('comentarios')?.touched  && this.proponerSFForm.get('comentarios')?.errors?.['required']">
                        El campo es requerido.
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>

          </div>
          <div class="modal-footer">
            <button type="button" class="bt btn-amarillo" (click)="guardarPropuestaSF()">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Proponer SF - Academico -->
    <div class="modal fade" id="idModalResolverAcademico" tabindex="-1" aria-labelledby="ModalCorreoExistente"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content p-3">
          <div class="modal-header">
            <h5 class="modal-title-resolver" id="ModalCorreoExistente"><b>Propuesta de Socio Formador.</b></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="">
              <div class="row d-flex jusitfy-content-center">
                <div class="col-3 atomic_spc">
                  <img class="img_avt_mn" alt="Socio Formador" *ngIf="propuestaSF?.urlLogo != ''"
                    src="{{ propuestaSF?.urlLogo }}" />
                </div>
                <div class="col-5 atomic_spc">
                  <div class="titsans_contact_b"><a href="" [routerLink]="" class="linkSF"
                      (click)="mostrarDetalle()"><strong>{{ propuestaSF?.nombreSocioFormador }}</strong></a></div>
                  <div class="subtgris">{{ propuestaSF?.rfc }}</div>
                </div>
                <div class="col-11 atomic_spc">
                  <div class="title-option">Comentarios:</div>
                  {{ propuestaSF?.comentarios }}
                </div>
                <hr>
              </div>

            </div>

            <form [formGroup]="proponerSFAcademicoForm">
              <div class="row">
                <div class="col-6">
                  <button type="button" class="bt btn-amarillo" (click)="aceptarPropuestaSF()">
                    Aceptar
                  </button>
                </div>
                <div class="col-6">
                  <button type="button" class="bt btn-blue" (click)="veRechazarPropuestaSF()">
                    Rechazar
                  </button>
                </div>

                <div class="col-12" *ngIf="verRechazoPropuestaSFComentarios">
                  <div class="container-contacto-enlace">
                    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12 mt-4">
                      <span class="title-option">{{ "solicitud_sf_potencial-rechazo.comentarios_contacto" | translate
                        }}</span><span class="text-danger">*</span><br />
                      <textarea style="border-radius: 5px; width: 100%;" rows="7" formControlName="comentarios"
                        placeholder='Puedes agregar un comentario para especificar detalles sobre tu propuesta de Socio Formador.'
                        required></textarea>

                      <div class="requerido"
                        *ngIf="this.proponerSFAcademicoForm.get('comentarios')?.touched  && this.proponerSFAcademicoForm.get('comentarios')?.errors?.['required']">
                        El campo es requerido.
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>

          </div>
          <div class="modal-footer" *ngIf="verRechazoPropuestaSFComentarios">
            <button type="button" class="bt btn-amarillo" (click)="rechazarPropuestaSF()">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>

</div>
