<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div>
	<div class="row">
		<div class="sec_ad">
			<app-title-show-more [showMoreObject]="titleSociosFormadores"></app-title-show-more>
			<div class="sec_tit">
				<h2></h2>
			</div>
			<app-btn-regresar></app-btn-regresar>
		</div>

		<div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
			<div class="active" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aprobar"
				aria-controls="tab-socios-formadores-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true"
				[innerHTML]="'solicitud-vinculacion-retos.solicitudes_por_aprobar' | translate ">

			</div>
			<div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aceptadas"
				aria-controls="tab-socios-formadores-aceptadas" class="" role="tab" type="button" aria-selected="false"
				[innerHTML]="'solicitud-vinculacion-retos.solicitudes_aceptadas' | translate">
			</div>
			<div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-rechazadas"
				aria-controls="tab-socios-formadores-rechazadas" class="" role="tab" type="button" aria-selected="false"
				[innerHTML]="'solicitud-vinculacion-retos.solicitudes_rechazadas' | translate">
			</div>
		</div>

		<div class="tab-content" id="pills-tabContent">
			<div class="tab-pane fade show active" id="tab-socios-formadores-aprobar" role="tabpanel"
				aria-labelledby="tab-socios-formadores-aprobar-tab">
				<app-solicitudes-vinculacion-retos-sa [statusType]="'aprobado'"></app-solicitudes-vinculacion-retos-sa>
			</div>
			<div class="tab-pane fade" id="tab-socios-formadores-aceptadas" role="tabpanel"
				aria-labelledby="tab-socios-formadores-aceptadas-tab">
				<app-solicitudes-vinculacion-retos-sa [statusType]="'aceptado'"></app-solicitudes-vinculacion-retos-sa>
			</div>
			<div class="tab-pane fade" id="tab-socios-formadores-rechazadas" role="tabpanel"
				aria-labelledby="tab-socios-formadores-rechazadas-tab">
				<app-solicitudes-vinculacion-retos-sa [statusType]="'rechazado'"></app-solicitudes-vinculacion-retos-sa>
			</div>
		</div>


	</div>

</div>
