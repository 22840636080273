import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solicitudes-vinculacion-retos',
  templateUrl: './solicitudes-vinculacion-retos.component.html',
  styleUrls: ['./solicitudes-vinculacion-retos.component.css']
})
export class SolicitudesVinculacionRetosComponent implements OnInit {

  sidebarExpanded = false;
   lang : string = 'es';

   titleSociosFormadores = {
    title: 'Socios Formadores que se quieren vincular a mis retos'
  };


  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') ?? 'es';
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }
}
