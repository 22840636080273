import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InicioService } from '@shared/service/Inicio/inicio.service';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  nombreUsuario!: string;
  idSocioFormador!: number;
  idUsuario: string;
  idCampus: string;
  imagenPerfil: string ="https://mvretosstorage.blob.core.windows.net/mvpretos-user-images/reto_4.jpg";
  clavePerfil!: string;
  NumeroMensajes: string = '0';
  contactoPrincipal: boolean = false;
  imgMenuFlecha='assets/img/icon_abajo.svg';

  public isCollapsed = true;

  constructor(
    private usuarioService: UsuariosService,
    private sociosFormadoresService: SociosFormadoresService,
    private router: Router,
    private inicio: InicioService) { }

  async ngOnInit(){

    let userStore = this.usuarioService.getCurrentUserData();
    this.nombreUsuario = userStore.nombreUsuario;
    this.idSocioFormador = userStore.idSocioFormador;
    this.imagenPerfil = userStore.urlFotoUsuario||'https://mvretosstorage.blob.core.windows.net/mvpretos-user-images/reto_4.jpg';
    this.clavePerfil = userStore.clavePerfil;
    this.idUsuario = userStore.idUsuario;
    this.idCampus = userStore.idCampus;
    this.contactoPrincipal = userStore.contactoPrincipal;

    await this.obtenerNumeroMensajes();
  }

  async obtenerNumeroMensajes() {
    try {
      let fechaNotificaicon = localStorage.getItem('NotiFec')|| '';
      let miDate = new Date();
      let hoy = miDate.getFullYear()+''+miDate.getMonth()+''+miDate.getDay();
      if(fechaNotificaicon == hoy )
      {
        this.NumeroMensajes = '0';
        return;
      }

      let idSocio = this.usuarioService.getCurrentUserData().idSocioFormador;
      let items;
      if (this.clavePerfil == 'socio') {
        items = <any>(
          await lastValueFrom(this.sociosFormadoresService
            .getNotificacionesByIdSocio(idSocio))
        );
      }
      if (this.clavePerfil == 'academia') {
        items = <any>(
          await lastValueFrom(this.usuarioService
            .getNotificacionesByIdAcacemia(this.idUsuario))
        );
      }
      if (this.clavePerfil == 'cvdp' || this.clavePerfil == 'cvdp_admin') {
        items = <any>(
          await lastValueFrom(this.usuarioService
            .getNotificacionesByIdCVDP(this.idCampus))
        );
      }

      this.NumeroMensajes = items.length;
      if (items.length > 9) {
        this.NumeroMensajes = '9+';
      }
    } catch (error) {
      console.log('error', error);
    }
  }

   logout() {
    this.usuarioService.logout();
  }

  ir(menuSeleccionado: string) {
    this.collapseToggle();
    this.inicio.changeSubMenuItem(menuSeleccionado);

    this.router.navigateByUrl('/mi-perfil');
  }

  collapseToggle(){
    this.isCollapsed = ! this.isCollapsed;
    this.imgMenuFlecha = (this.imgMenuFlecha === 'assets/img/icon_abajo.svg'?'assets/img/icon_arriba.svg':'assets/img/icon_abajo.svg');
  }

}
