import { Component, OnInit } from '@angular/core';
import { IAcademiaVinculacionRetosResponse } from '@shared/Interface/IAcademiaVinculacionRetosResponse';
import { Paginador } from '@shared/Interface/IPaginador.interface';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { DashboardService } from '@shared/service/Dashboard/dashboard.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-solicitudes-vinculacion-retos-sr',
  templateUrl: './solicitudes-rechazadas.component.html',
  styleUrls: ['./solicitudes-rechazadas.component.css']
})
export class SolicitudesVinculacionRetosSRComponent implements OnInit {

  sidebarExpanded = false;
   lang : string = 'es';
   maxChar: number = 40;

  vinculacionesRetosAllList: IAcademiaVinculacionRetosResponse[] = [];
  vinculacionRetosActualesList: IAcademiaVinculacionRetosResponse[] = [];
  vinculacionRetosActualesMovilList: IAcademiaVinculacionRetosResponse[] = [];

  idAcademico: number;
  estatus: string = "rechazado";
  registrosPagina: number = environment.RegistrosPagina;
  paginaActual: number = 1;
  paginaActualMasResultados: number = 1;
  totalPaginas: number = 0;
  pages: number[] = [];

  constructor(private dashboardService: DashboardService,
    private usuarioService : UsuariosService,
    private paginadorLibs: PaginadorLibsComponent) { }

  async ngOnInit(): Promise<void> {
    this.idAcademico = this.usuarioService.getCurrentUserData().idUsuario;
    this.lang = localStorage.getItem('lang') ?? 'es';
    await this.getListaVinculacionRetosActivos();
    this.getPaginacion();
    this.obtenerRegistrosActuales(1);
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }

  async getListaVinculacionRetosActivos() {
    try {
      let resultado = <any>await lastValueFrom(this.dashboardService.getAcademiaVinculaciones(this.idAcademico, this.estatus));
      this.vinculacionesRetosAllList = resultado;
    } catch (error) {
      console.error("Error al obtener los académicos vinculados a un reto");
    }
  }

  getPaginacion() {
    let paginador: Paginador = this.paginadorLibs.getPaginacion(this.vinculacionesRetosAllList.length, this.registrosPagina);
    this.totalPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;
  }

  obtenerRegistrosActuales(numPagina: number) {
    this.vinculacionRetosActualesList = this.paginadorLibs.obtenerRegistrosActuales(numPagina, this.registrosPagina, this.vinculacionesRetosAllList);
    this.vinculacionRetosActualesMovilList = this.vinculacionRetosActualesList;
    this.paginaActual = numPagina;
    this.paginaActualMasResultados = numPagina;
  }


  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.vinculacionRetosActualesMovilList = this.paginadorLibs.obtenerMasResultados(this.paginaActualMasResultados, this.registrosPagina, this.vinculacionesRetosAllList);
  }

  verReto(idRetoMaestro: number)
  {
    sessionStorage.setItem('idRetoMaestro', idRetoMaestro.toString());
    let url = `/socio/pagina-retos`;
    window.location.href=url;

  }
}
