import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";

@Component({
  selector: "app-gestion-usuarios",
  templateUrl: "./gestion-usuarios.component.html",
  styleUrls: ["./gestion-usuarios.component.css"],
})
export class GestionUsuarioComponent implements OnInit {
  sidebarExpanded = false;
  lang: string = "es";
  activeTab: string="socios";

  constructor(
      private toaster: ToastrService, 
      private router: Router,
      private usuarioService: UsuariosService
      ) {}

  ngOnInit(): void {
    localStorage.removeItem('AU-Ctx-nU');
    this.lang = localStorage.getItem("lang") ?? "es";

    let savedTab = localStorage.getItem('tabGE');
    if(savedTab){
      this.activeTab = savedTab;
    } 

  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  agregarUsuario() {
    localStorage.setItem('AU-Ctx-nU', 'true');
    this.router.navigate(["new-user"]);
  }

  filter = { query: "" };
  
  // Se agrega función compatible con el buscador MainLayoutBuscador
  async buscadorPress(keyWord: any) {
    // Aquí iría lo que el padre tenga que hacer
    
    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      this.filter.query = keyWord;
    } else {
      this.filter.query = "";
    }
    this.usuarioService.queryFilter$.emit(this.filter.query);
  }
    
changetab(tab: string) {
    localStorage.setItem('tabGE',tab);
  }    
}
