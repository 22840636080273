import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICampus } from '@shared/Interface/ICatalogos.interface';
import { Paginador } from '@shared/Interface/IPaginador.interface';
import { IUsuariosCVDPResponse } from '@shared/Interface/IUsuariosCVDPResponse';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { tap, lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

export interface IcampusObj {
  campus: string;
  id: number;
  usuarios: IUsuariosCVDPResponse[];
}

@Component({
  selector: 'app-cvdp',
  templateUrl: './cvdp.component.html',
  styleUrls: ['./cvdp.component.css'],
})
export class GestionUsuarioCVDPomponent implements OnInit {
  sidebarExpanded = false;
  lang: string = 'es';
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  numeroPaginasTo = 0;
  usuariosList: IUsuariosCVDPResponse[] = [];
  usuariosListTotal: [] = [];
  elementsTo: IcampusObj[] = [];
  elementsTotal: IcampusObj[] = [];
  paginaActualMasResultados: number = 0;
  ordenamientoBy: string = 'nombre'; //ajustar al parametro que recibe la api "nombre/campus"
  textOrdenarPor: string = 'gestion_usuarios.ordenar_por';

  queryFilter:string = '';
  campusData:ICampus[];

  constructor(
    private _usuariosService: UsuariosService,
    private paginadorLibs: PaginadorLibsComponent,
    private toastr: ToastrService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private catalogosService: CatalogosService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem('lang') ?? 'es';
    await this.getUsuarios(this.ordenamientoBy);

    this._usuariosService.queryFilter$.subscribe( text => {
      this.queryFilter = text;
      this.getUsuarios(this.ordenamientoBy);
    });

  }

  async getUsuarios(ordenamiento: string) {
    try {
      if(!this.campusData){
        this.campusData = await lastValueFrom(this.catalogosService.getCampus());
      }

      this.usuariosList= [];
      this.usuariosListTotal = [];
      let response1 = <any>await lastValueFrom(this._usuariosService
        .getUsuariosCVDPByFiltro(ordenamiento))

        let response = response1.map((user:IUsuariosCVDPResponse)=>({
          ...user,
          lblEstatus: (user.estatus.trim()=='activo' && user.avisoPrivacidad == 1 && user.fechaUltimoLogin ?'Activo':'Inactivo'),
          campusPrincipal: (this.campusData.filter(x => x.idCVDPPrincipal == user.idUsuario).length>0 ? 'CVDP Principal' : 'CVDP Secundario'),
          //TODO: Agregar nivel de gestion
          nivelGestion: user.nivelGestion ?? 'Local'
       }));

      if (response != null && response != undefined && response.length > 0) {
        if(this.queryFilter!=''){
          this.usuariosListTotal = response.filter((user :IUsuariosCVDPResponse) => {
            let filter = this.queryFilter.toUpperCase();
            return user.nombreUsuario.toUpperCase().includes(filter)
                  || user.campus?.toUpperCase().includes(filter)
                  || user.lblEstatus.toUpperCase()== filter
          });

        }
        else{
          this.usuariosListTotal = response;
        }

        this.showPaginationData(this.usuariosListTotal.slice(0,this.numberPerPage));
      }
    } catch (error) {
      console.error('Error al realizar la peticion', error);
    }
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.elementsTotal
    );
  }

  async agrugarBySF() {
    this.elementsTotal = [];
    let index = 1;
    this.usuariosListTotal.forEach((element: IUsuariosCVDPResponse) => {
      let sf: IcampusObj = {
        campus: element.campus,
        id: index,
        usuarios: [],
      };
      sf.usuarios.push(element);

      let buscar = this.elementsTotal.filter(
        (el) => el.campus == element.campus
      );
      if (buscar.length > 0) {
        this.elementsTotal.forEach((elementTo) => {
          if (elementTo.campus == element.campus) {
            let buscarUser = elementTo.usuarios.filter(
              (el) => el.idContacto == element.idContacto
            );
            if (buscarUser.length == 0) {
              elementTo.usuarios.push(element);
            }
          }
        });
      } else {
        this.elementsTotal.push(sf);
      }
      index++;
    });

    this.obtenerMasResultados();
    let paginador: Paginador = this.paginadorLibs.getPaginacion(
      this.elementsTotal.length,
      this.numberPerPage
    );
    this.numeroPaginasTo = paginador.totalPaginas;
  }

  async orderBy(ordenamiento: string) {
    switch (ordenamiento) {
      case 'nombre':
        this.textOrdenarPor = 'gestion_usuarios.nombre_az';
        break;

      case 'campus':
        this.textOrdenarPor = 'gestion_usuarios.campus';
        break;
    }

    this.ordenamientoBy = ordenamiento;
    await this.getUsuarios(ordenamiento);

  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  editarUsuario(idUsuario: number) {
    this.router.navigate([`edit-user/cvdp/${idUsuario}`]);
  }


  async eliminarUsuario(idUsuario: number) {

    Swal.fire({
      title: '¿Desea eliminar el usuario?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {


      this._usuariosService
        .delteUser(idUsuario)
        .pipe(
          tap((resultado: any) => {
            if (resultado) {
              Swal.fire('Gestión de Usuarios.', 'Usuario Eliminado.', 'success');

              this.ngOnInit();
            } else {
              this.toastr.error('Algo Salió mal!');
            }
          })
        )
        .subscribe();
      }
    });


  }

  agregarUsuario() {
    localStorage.setItem('AU-Ctx-nU', 'true');
    this.router.navigate(['new-user/cvdp']);
  }

  showPaginationData(objData: any) {
    if (objData.length) {

      this.usuariosList = objData;

      this.changeDetector.detectChanges();
    }
  }
  restablecer(){

    window.location.reload();
  }

  verDatosUsuario(idUsuario, objCvdp){
    this._usuariosService.getUsuarioById(idUsuario).subscribe({
      next: (resOK)=>{

        let mensaje = '<div class="row text-start w-100">' +
      '<div class="col-sm-6 "><h5>Nombre:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.nombreUsuario + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Apellidos:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.apellidosUsuario + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Correo Electrónico:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.usuario + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Nómina:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.nomina + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Campus:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.campus + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Región:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + objCvdp.region + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Estatus:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + objCvdp.lblEstatus + '</h6></div>' +

	  '</div>';

        Swal.fire({
          title:'Datos del Cvdp',
          width: 600,
          html:mensaje
        });
      }
      ,error:(error)=>{
        console.log(error);
      }
    });

  }

  abrirNotificaciones(el){
    sessionStorage.setItem('usrNot',JSON.stringify(el));
    sessionStorage.setItem('usrPerfNot',"cvdp");
    this.router.navigate(['/admin/notificaciones']);
  }

  async validaEstablecerPrincipal(objUsuario){
    if(objUsuario.lblEstatus.toUpperCase()  != 'ACTIVO'){
      Swal.fire({
        title:"Usuario no activo",
      html: `El usuario no esta activo: <br/>Aviso de Privacidad: ${objUsuario.avisoPrivacidad ? 'Si' : 'No'} <br/>Estatus: ${objUsuario.estatus}<br/> Último login: ${objUsuario.fechaUltimoLogin??''}`,
      icon: 'warning',
      showCancelButton: true,})
      .then((res)=>{

        if(res.isConfirmed){
          this.establecerPrincipal(objUsuario);
      }
    });
    }
    else{
      this.establecerPrincipal(objUsuario);
    }
  }
  async establecerPrincipal(objUsuario) {
    let idUsuario = objUsuario.idUsuario;
    try{

      // *** Revisar si hay otros contactos principales
      // Obtener el campus
      let campusData:ICampus[] = await lastValueFrom(this.catalogosService.getCampus());

      // saber si el campus tiene cvdp principal y si es el mismo
      if(!campusData){
        this.toastr.error("Error al convertir en principal, intente más tarde.");
        return;
      }

      // Obtener el campus del usuario
      let usuarioSelData = await lastValueFrom(this._usuariosService.getUsuarioById(idUsuario));
      let idCampusUsuario = usuarioSelData.idCampus;
      if(!idCampusUsuario){
        this.toastr.error("El usuario no tiene campus asociado");
        return;
      }


      let campusSeleccionado = campusData.filter(x => x.idCampus == idCampusUsuario).pop();
      if(!campusSeleccionado){
        this.toastr.error("No se ha encontrado el campus del usuario");
        return;
      }

      if((campusSeleccionado.idCVDPPrincipal ?? 0) > 0){
        let usuarioCVDPPrincipal = await lastValueFrom(this._usuariosService.getUsuarioById(campusSeleccionado.idCVDPPrincipal))
        Swal.fire({
              title: "¿Desea cambiar el CVDP principal?",
              html: `El CVDP principal actual es:<br/> ${usuarioCVDPPrincipal?.nombreUsuario} ${usuarioCVDPPrincipal?.apellidosUsuario} - ${usuarioCVDPPrincipal?.usuario.toLowerCase()}`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Cambiar CVDP Principal",
            }).then((result) => {
              if (result.isConfirmed) {
                // se actualiza el contacto principal
                this.actualizaCVDPPrincipal(idUsuario, idCampusUsuario, usuarioSelData);
              }
            });
      }
      else{
        this.actualizaCVDPPrincipal(idUsuario, idCampusUsuario, usuarioSelData);
      }

    } catch (err){
      console.log("Error principal: ",err);
    }

  }

  async actualizaCVDPPrincipal(idUsuario, idCampusUsuario, usuarioSelData){
    try{
        await lastValueFrom(this._usuariosService.putUsuarios({...usuarioSelData, cvdpPrincipal:true}));
        this.campusData = await lastValueFrom(this.catalogosService.getCampus());
        await this.getUsuarios(this.ordenamientoBy);
        this.toastr.success("Actualizado con éxito");
    }catch(err){
      console.log(err);
      this.toastr.error("Hubo un error. Intentar más tarde");
    }

  }


}
