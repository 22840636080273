import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Paginador } from "@shared/Interface/IPaginador.interface";
import { IUsuariosSFResponse } from "@shared/Interface/IUsuariosSFResponse";
import { PaginadorLibsComponent } from "@shared/libs/paginador.component";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { tap, lastValueFrom } from "rxjs";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import Swal from "sweetalert2";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";

export interface IsociosFormadoresObj {
  idSocioFormador: number;
  idContacto: number;
  nombreSocioFormador: string;
  usuarios: IUsuariosSFResponse[];
}

@Component({
  selector: "app-socios-formadores",
  templateUrl: "./socios-formadores.component.html",
  styleUrls: ["./socios-formadores.component.css"],
})
export class GestionUsuarioSFComponent implements OnInit {
  sidebarExpanded = false;
  lang: string = "es";
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  numeroPaginasTo = 0;
  usuariosList: IUsuariosSFResponse[] = [];
  usuariosListTotal: [] = [];
  elementsTo: IsociosFormadoresObj[] = [];
  elementsTotal: IsociosFormadoresObj[] = [];
  paginaActualMasResultados: number = 0;
  ordenamientoBy: string = "nombre"; //ajustar al parametro que recibe la api "nombre/socio"
  textOrdenarPor: string = "gestion_usuarios.ordenar_por";

  queryFilter: string = "";

  sociosFormadoresData: ISocioFormadorResponse[];

  constructor(
    private _usuariosService: UsuariosService,
    private paginadorLibs: PaginadorLibsComponent,
    private toaster: ToastrService,
    private router: Router,
    private contactosService: ContactosService,
    private changeDetector: ChangeDetectorRef,
    private socioFormadorService: SociosFormadoresService
  ) {  }


  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") ?? "es";
    await this.getUsuarios(this.ordenamientoBy);

    this._usuariosService.queryFilter$.subscribe( async (text) => {
      this.queryFilter = text;
       await this.getUsuarios(this.ordenamientoBy);
    });
  }

  async getUsuarios(ordenamiento: string) {
    try {
      this.usuariosListTotal = [];
      this.usuariosList = [];
      let response1 = <any>(await lastValueFrom(this._usuariosService.getUsuariosSFByFiltro(ordenamiento)));
      if(!this.sociosFormadoresData){
        this.sociosFormadoresData= await lastValueFrom(this.socioFormadorService.getAllSociosFormadores("",0,0,"",0,"activo",0,false));
      }
      let response = response1.map((user:IUsuariosSFResponse)=>({
        ...user,
        lblEstatus: (user.estatus.trim()=='activo' && user.avisoPrivacidad == 1 && user.fechaUltimoLogin ?'Activo':'Inactivo'),
        rfc: (this.sociosFormadoresData.filter((x)=> {return (x.idSocioFormador == user.idSocioFormador)})).pop()?.rfc,
        //TODO: agregar Region de Atención
        regionAtencion: user.region ?? '-',

      }));

      if (response != null && response != undefined && response.length > 0) {
        if (this.queryFilter != "") {
          this.usuariosListTotal = response.filter(
            (user: IUsuariosSFResponse) => {
              let filter = this.queryFilter.toUpperCase();
              return (
                user.nombreSocioFormador
                  .toUpperCase()
                  .includes(filter) ||
                user.nombreContacto
                  .toUpperCase()
                  .includes(filter) ||
                user.lblEstatus
                  .toUpperCase()
                  == filter ||
                user.rfc
                  .toUpperCase()
                  .includes(filter)
              );
            }
          );
        } else {
          this.usuariosListTotal = response;
        }
        this.showPaginationData(
          this.usuariosListTotal.slice(0, this.numberPerPage)
        );
      }
    } catch (error) {
      console.log("Error al realizar la peticion", error);
    }
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.elementsTotal
    );
  }

  async agrugarBySF() {
    this.elementsTotal = [];
    this.usuariosListTotal.forEach((element: IUsuariosSFResponse) => {
      let sf: IsociosFormadoresObj = {
        idContacto: element.idContacto,
        idSocioFormador: element.idSocioFormador,
        nombreSocioFormador: element.nombreSocioFormador,
        usuarios: [],
      };
      sf.usuarios.push(element);

      let buscar = this.elementsTotal.filter(
        (el) => el.idSocioFormador == element.idSocioFormador
      );
      if (buscar.length > 0) {
        this.elementsTotal.forEach((elementTo) => {
          if (elementTo.idSocioFormador == element.idSocioFormador) {
            let buscarUser = elementTo.usuarios.filter(
              (el) => el.idContacto == element.idContacto
            );
            if (buscarUser.length == 0) {
              elementTo.usuarios.push(element);
            }
          }
        });
      } else {
        this.elementsTotal.push(sf);
      }
    });

    this.obtenerMasResultados();
    let paginador: Paginador = this.paginadorLibs.getPaginacion(
      this.elementsTotal.length,
      this.numberPerPage
    );
    this.numeroPaginasTo = paginador.totalPaginas;
  }

  async orderBy(ordenamiento: string) {
    switch (ordenamiento) {
      case "nombre":
        this.textOrdenarPor = "gestion_usuarios.nombre_az";
        break;

      case "socio":
        this.textOrdenarPor = "gestion_usuarios.socio_formador";
        break;
    }

    this.ordenamientoBy = ordenamiento;
    await this.getUsuarios(ordenamiento);

  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  editarUsuario(idSocio: number, idContacto: number, user:IUsuariosSFResponse) {
    sessionStorage.setItem("gu-user-obj", JSON.stringify(user));
    this.router.navigate([`edit-user/socio/${idSocio}/${idContacto}`]);
  }

  eliminarUsuario(idSocio: number, idContacto: number) {
    this.contactosService
      .deleteContact(idSocio, idContacto)
      .pipe(
        tap((resultado: any) => {
          if (resultado) {
            this.toaster.success("Eliminado Correctamente!");
            this.ngOnInit();
          } else {
            this.toaster.error("Algo Salió mal!");
          }
        })
      )
      .subscribe();
  }

  showPaginationData(objData: any) {
    if (objData.length) {
      this.usuariosList = objData;

      this.changeDetector.detectChanges();
    }
  }

  restablecer() {

    window.location.reload();
  }

  establecerPrincipal(idSocio: number, idContacto: number) {
    // revisar si hay otros contactos principales
    this.contactosService.getContactos(idSocio, true).subscribe({
      next: (resOK) => {
        // hay contacto principal
        let contacto = resOK.pop();

        Swal.fire({
          title: "Desea cambiar el contacto principal?",
          text: `El contacto principal actual es: ${contacto?.nombre} ${contacto?.apellidos} - ${contacto?.email}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Cambiar contacto Principal",
        }).then((result) => {
          if (result.isConfirmed) {
            // se actualiza el contacto principal
            this.actualizaContactoPrincipal(idSocio, idContacto);
          }
        });
      },
      error: (error) => {
        // No hay contacot principal
        console.log("Este es el error: ", error);
        console.log("s:",idSocio," c:", idContacto);
        // se actualiza el contacto principal
        this.actualizaContactoPrincipal(idSocio, idContacto);
      }
    });
  }

   actualizaContactoPrincipal(idSocio, idContacto){
    this.contactosService.getContactoxId(idSocio, idContacto).subscribe({
      next: (resOK: IContacto) => {
        let contacto = resOK;
        if (contacto) {
          contacto.contactoPrincipal = true;
          this.contactosService.putContactos(idSocio, contacto).subscribe({
            next: async (resOK) => {
              this.toaster.success("Actualizado con éxito");
              await this.getUsuarios(this.ordenamientoBy);
            },
            error: (error) => {
              console.log("2", error);
            }
          });
        }
      },
      error: (error) => {
        console.log("1", error);
      }
    });
  }

  verDatosContacto(idSocio, idContacto, objCvdp, nombreSocioFormador){
    this.contactosService.getContactoxId(idSocio, idContacto).subscribe({
      next: (resOK)=>{

        let mensaje = '<div class="row text-start w-100">' +
      '<div class="col-sm-6 "><h5>Nombre:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.nombre + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Apellidos:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.apellidos + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Área:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.area + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Puesto:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.puesto + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Correo Electrónico:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.email + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Tel. Oficina:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.telefonoOficina + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Tel. Celular:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.telefonoMovil + '</h6></div>' +
      '<div class="col-sm-6 "><h5>EXATEC:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + (resOK.exatec?'Si':'No') + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Estatus:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + objCvdp.lblEstatus + '</h6></div>' +
      '<div class="col-sm-6 "><h5>Tipo Contacto:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + (objCvdp.contactoPrincipal?'Principal':'Secundario') + '</h6></div>' +
	  '</div>';

        Swal.fire({
          title: nombreSocioFormador + '<br/> Datos del Contacto',
          width: 600,
          html:mensaje
        });
      }
      ,error:(error)=>{
        console.log(error);
      }
    });

  }

  abrirNotificaciones(el){
    sessionStorage.setItem('usrNot',JSON.stringify(el));
    sessionStorage.setItem('usrPerfNot',"socio");
    this.router.navigate(['/admin/notificaciones']);
  }

  mostrarContactosSecundarios : boolean=false;
  MostrarContactosSecundarios(){
    this.mostrarContactosSecundarios = ! this.mostrarContactosSecundarios;
  }

  agregarUsuario(){
    this.router.navigate(['admin/gestion-contactos']).catch((err)=> console.log(err));
  }
}
