import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Utils from "@shared/helpers/utils";
import {
  CUnidadFormativa,
  ICampus,
  IEscuela,
  IPeriodo,
  IPeriodoSemanal,
} from "@shared/Interface/ICatalogos.interface";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { cRetoMaestro, IRetoMaestro } from "@shared/Interface/IReto.interface";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { cSolicitudGetResponse } from "@shared/Interface/ISolicitudGetResponse";
import { ISolicitudRequest } from "@shared/Interface/ISolicitudRequest";
import { ISolicitudComentarios } from "@shared/Interface/ISolicitudSocioComentarios";
import { IUsuariosAcademiaResponse } from "@shared/Interface/IUsuariosAcademiaResponse";
import { IUser } from "@shared/Interface/user";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { ProblematicasService } from "@shared/service/Problematicas/problematicas.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, tap } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-solicitud-vinculacion-a-reto",
  templateUrl: "./solicitud-vinculacion-a-reto.component.html",
  styleUrls: ["./solicitud-vinculacion-a-reto.component.css"],
})
export class SolicitudVinculacionARetoComponent implements OnInit {
  periodosData: IPeriodo[];
  idReto: string | null;
  typeSolicitud: string | null;
  idAcademico: string | null;
  idSocioFormador: string | null;
  storeUser: IUser;
  profesorSolicita: any;
  retoInformacion: IRetoMaestro;
  socioFormador: ISocioFormadorResponse;
  contactoPrincipal: IContacto | null;
  idSolicitud: any;
  solicitudData: any;
  clavePerfil: string;
  titleSolicitud = {
    title: "Solicitud de Socio Formador que se quiere vincular a mis retos",
  };

  muestraAlerta: boolean = true;
  muestraBotones: boolean = false;

  campus: ICampus[];

  contactRequest = {
    nombre: "Beatriz",
    apellidos: "Pinzón Solano",
    area: "Adquisición de Talento y Recursos Humanos",
    img: "avatar_1",
    isContact: true,
    removedButtons: true,
  };

  // Secciones de cuadro
  lbl_alcance = "Multi-Alcance";
  lbl_semestre = "Multi-Semestre";
  lbl_semana_numero = "1";
  lbl_semana = "1 Semana";
  lbl_idioma_numero = "Es";
  lbl_idioma = "Multi-Idioma";

  // seccion detalle
  lbl_det_alcance = "";
  lbl_det_semestre = "";
  lbl_det_idioma = "";

  lbl_det_programaAcademico = "";

  lbl_sf_sectores = "Sector:<br /> ";
  lbl_sf_tamanos = "Tamaño:<br /> ";
  lbl_sf_industria = "Industria:<br /> ";
  lbl_sf_presencias = "Presencia:<br /> ";
  lbl_sf_descripcion = "";

  lbl_periodosSemestrales: string = "";
  lbl_periodosSemanales: string = "";
  lbl_programasAcademicos: string = "";
  lbl_idiomas: string = "";
  lbl_periodo: string = "";

  lbl_listado_campus = "";
  lbl_campus_coordinador = "";

  profesoresxCampus: IUsuariosAcademiaResponse[];
  profesorSeleccionado: number;
  idAcademia: number;

  lblProfesorAsignado = "No asignado";
  ROLES_ACADEMICOS_MOSTRAR: number[] = [0];

  // datos de la busqueda por algoritmo
  lbl_M_areas: string = "-";
  lbl_M_alcance: string = "-";
  lbl_M_contexto: string = "-";
  lbl_M_objetivos: string = "-";
  busquedaPorBancodeRetos: boolean = false;

  periodoAcademicoData: IPeriodo[];
  campusData: ICampus[];
  seccion1: ICampus[];
  seccion2: ICampus[];

  periodoAcademicoSeleccionado: number;

  pedirListaCampus = false;
  pedirCampusCoordinador = false;
  pedirPeriodoAcademico = false;
  selectedCampus: ICampus[] = [];

  // Vinculacion
  vinculacionForm: FormGroup;
  mostrarRequeridos: boolean = false;
  pedirPeriodo: boolean = false;
  pedirPeriodoSemanal: boolean = false;
  pedirModalidad: boolean = false;
  periodoSemanalData: IPeriodoSemanal[];
  periodoSemanalChk: number[] = [];
  escuelaData: any;

  unidadFormativaData: CUnidadFormativa[];
  tieneReto: boolean = false;
  NombreReto: string;
  retoInfo: any;
  lbl_periodoSemanal = "";
  lbl_periodoSemestral = "";

  lbl_comentarios: string = "Sin comentarios";

  constructor(
    private route: ActivatedRoute,
    private retosService: RetosService,
    private socioFormadorService: SociosFormadoresService,
    private contactoService: ContactosService,
    private usuariosService: UsuariosService,
    private solicitudesService: SolicitudesService,
    private toastr: ToastrService,
    private routr: Router,
    private catalogosService: CatalogosService,
    private fb: FormBuilder,
    private problematicasService: ProblematicasService
  ) {
    this.catalogosService
      .getPeriodo(false)
      .pipe(tap((response) => (this.periodosData = response)))
      .subscribe();

    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campus = resultado)))
      .subscribe();

    // Cargar Periodos   AGO-DIC 2023
    this.catalogosService
      .getPeriodo()
      .pipe(
        tap((resultado: IPeriodo[]) => (this.periodoAcademicoData = resultado))
      )
      .subscribe();
  }

  async ngOnInit() {
    this.retoInformacion = cRetoMaestro.retoMaestroFromJSon({});

    this.solicitudData = new cSolicitudGetResponse();
    this.idReto = this.route.snapshot.paramMap.get("idReto");
    this.idSolicitud = this.route.snapshot.paramMap.get("idSolicitud");
    this.idSocioFormador = this.route.snapshot.paramMap.get("idSocioFormador");
    this.getRetoInfo(this.idReto);
    this.getSolicitud(this.idSolicitud);
    this.getSocioFormadorInfo(this.idSocioFormador);
    this.storeUser = this.usuariosService.getCurrentUserData();
    this.clavePerfil = this.usuariosService.getCurrentUserData().clavePerfil;

    this.getEstatus();
    // Cargar campus donde llevar el reto
    this.catalogosService
      .getCampusxReto(Number(this.idReto))
      .pipe(
        tap((resultado: ICampus[]) => {
          this.campusData = resultado;

          // Se realiza ordenamiento alfabetico en dos columnas solicitado
          if (this.campusData) {
            let halfItems = Math.round(this.campusData.length / 2);
            this.seccion1 = this.campusData.slice(0, halfItems);
            this.seccion2 = this.campusData.slice(halfItems);
          }
        })
      )
      .subscribe();

    this.initializeForm();
    this.initFormEstatusSolForm();
    this.initFormVinculacionEdicion();
  }

  buildDatatoDisplay() {
    this.lbl_periodosSemestrales = Utils.ListToString(this.retoInformacion.periodosSemestrales,'periodoSemestral');
    this.lbl_periodosSemanales = Utils.ListToString(this.retoInformacion.periodosSemanales,'periodoSemanal');
    this.lbl_idiomas = Utils.ListToString(this.retoInformacion.idiomas,'idioma');
    this.lbl_programasAcademicos = Utils.ListToString(this.retoInformacion.programasAcademicos,'programaAcademico');
    this.lbl_sf_sectores += Utils.ListToString(this.retoInformacion.sectores, 'sector');
    this.lbl_sf_tamanos += Utils.ListToString(this.retoInformacion.tamanos, 'tamano');
    this.lbl_sf_industria += Utils.ListToString(this.retoInformacion.industrias, 'industria');
    this.lbl_sf_presencias += Utils.ListToString(this.retoInformacion.presencias, 'presencia');

    this.lbl_sf_descripcion +=
      this.retoInformacion.palabrasClave || "Sin descripción de Socio Formador";

    this.lbl_det_alcance = Utils.ListToString(this.retoInformacion.alcances, 'alcance');

    if (!this.lbl_det_alcance.includes(",")) {
      this.lbl_alcance = this.lbl_det_alcance;
    }
    this.lbl_alcance = this.lbl_alcance.length == 0 ? "-" : this.lbl_alcance;

    this.lbl_det_semestre = Utils.ListToString(this.retoInformacion.semestres, 'semestre');

    if (!this.lbl_det_semestre.includes(",")) {
      this.lbl_semestre = `${this.lbl_det_semestre} Semestre`;
    }
    this.lbl_semestre = this.lbl_semestre.length == 0 ? "-" : this.lbl_semestre;

    this.lbl_semana = this.retoInformacion.semanas || "";
    if (this.lbl_semana.length > 0) {
      this.lbl_semana_numero = this.lbl_semana.replace(" semanas", "");
    }
    this.lbl_semana_numero =
      this.lbl_semana_numero.length == 0 ? "-" : this.lbl_semana_numero;

    this.lbl_det_idioma = Utils.ListToString(this.retoInformacion.idiomas, 'idioma');

    if (this.retoInformacion.idiomas.length > 1) {
      this.lbl_idioma_numero = "*";
    } else {
      this.lbl_idioma_numero = this.lbl_det_idioma.slice(0, 2);
    }
    this.lbl_det_idioma =
      this.lbl_det_idioma.length == 0 ? "-" : this.lbl_det_idioma;
  }

  getRetoInfo(idReto: any) {
    this.retosService
      .getRetoMaestroDetail(idReto)
      .pipe(
        tap((response) => {
          if (response) {
            this.retoInformacion = response;

            this.buildDatatoDisplay();
            this.getProfesoresPorCampus(
              this.solicitudData.idCampus,
              this.retoInformacion.idEscuela
            );
          }
        })
      )
      .subscribe();
  }

  getSocioFormadorInfo(idSocioFormador: any) {
    this.socioFormadorService
      .getSociosFormadoresById(idSocioFormador)
      .pipe(tap((response) => (this.socioFormador = response)))
      .subscribe();
    this.contactoService
      .getContactos(idSocioFormador, true)
      .pipe(tap((response) => (this.contactoPrincipal = response[0])))
      .subscribe();
  }

  getAcademicoInfo(idUsuario: any) {
    this.usuariosService
      .getUsuarioById(idUsuario)
      .pipe(tap((response) => (this.profesorSolicita = response)))
      .subscribe();
  }

  getSolicitud(idSolicitud: any) {
    this.solicitudesService
      .getSolicitudById(idSolicitud)
      .pipe(
        tap((response) => {
          this.solicitudData = response;

          this.lbl_periodo =
            this.periodosData
              .filter((x) => x.idPeriodo == response.idPeriodo)
              .pop()?.periodo ?? "-";

          this.periodoAcademicoSeleccionado = response.idPeriodo;

          this.lbl_listado_campus = this.solicitudData.campus
            .map((x) => x.campus)
            .join(", ");

          let campusTmp: ICampus[] = this.solicitudData.campus.filter(
            (x) => x.campusCoordinador
          );
          this.lbl_campus_coordinador = "";
          if (campusTmp.length > 0) {
            this.lbl_campus_coordinador = campusTmp[0].campus;
          }

          if (response.idLogBusquedaReto == 0) {
            this.busquedaPorBancodeRetos = true;
          } else
            if(response.idProblematica > 0){
              this.getProblematicas(response.idProblematica);
            }


          this.lbl_comentarios = response.motivoRechazo ?? "Sin comentarios";

          if(this.lbl_comentarios!="Sin comentarios" && response.idCampus != campusTmp[0].idCampus){
            this.lblCampusReasignado = "(" + (this.campusData.find(x => x.idCampus == response.idCampus)?.campus ?? '') + ")";
          }

          this.estatusSolForm
            .get("estatus")
            ?.patchValue(this.solicitudData.claveEstatus);

          this.getSolicitudComentarios();
          this.mostrarBotones();
        })
      )
      .subscribe();
  }

  handleAceptar() {
    this.mostrarRechazo = false;
    this.mostrarReasignar = false;
    this.mostrarAceptarAcademico = true;
  }

  handleRechazar() {
    this.mostrarAceptarAcademico = false;
    this.mostrarReasignar = false;
    this.mostrarRechazo = true;

    Swal.fire({
      input: "textarea",
      inputLabel: "Motivo de rechazo",
      inputPlaceholder: "Escribe el motivo del rechazo",
      inputAttributes: {
        "aria-label": "Escribe el motivo del rechazo",
      },
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.motivoRechazo = response.value;
        this.enviarSolicitud();
      }
    });
  }

  mostrarBotones() {
    if (["cvdp", "cvdp_admin"].includes(this.storeUser.clavePerfil)) {
      if (
        ["aprobado", "rechazado", "aceptado", "no-aceptado"].includes(
          this.solicitudData.claveEstatus
        )
      ) {
        this.muestraAlerta = true;
        this.muestraBotones = true;
      }
      if (
        ["registrado", "revision"].includes(this.solicitudData.claveEstatus)
      ) {
        this.muestraAlerta = true;
        this.muestraBotones = true;
      }
    }
    if (this.storeUser.clavePerfil == "academia") {
      if (["aceptado", "rechazado",'no-aceptado'].includes(this.solicitudData.claveEstatus)) {
        this.muestraAlerta = true;
        this.muestraBotones = false;
      } else {
        this.muestraAlerta = true;
        this.muestraBotones = true;
      }
    }
  }

  handleAceptarAcademico() {
    this.solicitudesService
      .putSolicitudEstatus({
        idUsuario: this.storeUser.idUsuario,
        idSolicitud: this.idSolicitud,
        claveEstatus: "aceptado",
      })
      .subscribe({
        next: (resultOK) => {
          this.toastr.success("Solicitud aceptada.");
          this.routr.navigateByUrl("/dashboard");
        },
        error: (error) => {
          this.toastr.error("Hubo un error, intenta más tarde.");
          console.log("aceptado", error);
        },
      });
  }

  handleRechazarAcademico() {
    this.solicitudesService
      .putSolicitudEstatus({
        idUsuario: this.storeUser.idUsuario,
        idSolicitud: this.idSolicitud,
        claveEstatus: "rechazado",
      })
      .subscribe({
        next: (resultOK) => {
          this.toastr.success("Solicitud No Aceptada.");
          this.routr.navigateByUrl("/dashboard");
        },
        error: (error) => {
          this.toastr.error("Hubo un error, intenta más tarde.");
          console.log("no-aceptado", error);
        },
      });
  }

  handleRevertirRechazo() {
    this.solicitudesService
      .putSolicitudEstatus({
        idUsuario: this.storeUser.idUsuario,
        idSolicitud: this.idSolicitud,
        claveEstatus: "revision",
      })
      .subscribe({
        next: (resultOK) => {
          this.toastr.success("Solicitud en Revisión.");
          this.routr.navigateByUrl("/dashboard");
        },
        error: (error) => {
          this.toastr.error("Hubo un error, intenta más tarde.");
          console.log("revision", error);
        },
      });
  }

  verSocioFormador(idSocioFormador: number) {
    localStorage.removeItem("idSocioFormador");
    localStorage.setItem("idSocioFormador", idSocioFormador.toString());
    localStorage.setItem("origenVinculacion", "buscaSF");
    let url = `/socio-formador`;
    window.location.href = url;
  }

  seleccionarProfesor($event) {
    this.profesorSeleccionado = $event?.target?.value ?? $event.idUsuario;
  }

  seleccionarCampus($event) {
    this.campusSeleccionado = $event?.target?.value ?? $event.idCampus;
  }

  getProfesoresPorCampus(idCampus: number, idEscuela: number) {
    let avisoPrivacidad = 1;
    this.usuariosService
      .getUsuariosAcademiaRegion(
        "nombre",
        "activo",
        idCampus,
        idEscuela,
        avisoPrivacidad,
        this.ROLES_ACADEMICOS_MOSTRAR[0]
      )
      .subscribe({
        next: (resOK) => {

          let acafromCampus = resOK.filter( (acad) => acad.idCampus == idCampus);

          this.profesoresxCampus = acafromCampus.filter(
            (acad) =>
              acad.avisoPrivacidad != null &&
              acad.fechaUltimoLogin !=null  &&
              acad.estatus == "activo" &&
              acad.idCampus == idCampus
          );

          if (this.solicitudData.idAcademia > 0) {
            this.lblProfesorAsignado =
              this.profesoresxCampus.filter(
                (x) => x.idUsuario == this.solicitudData.idAcademia
              )[0].nombreUsuario || "";
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  handleReasignar() {
    this.mostrarAceptarAcademico = false;
    this.mostrarRechazo = false;
    this.mostrarReasignar = true;
  }

  enviarSolicitud() {
    if (this.mostrarAceptarAcademico) {
      if ((this.profesorSeleccionado ?? 0) === 0) {
        this.toastr.error("Debe seleccionar un profesor");
        return;
      }

      this.solicitudesService
        .putSolicitudEstatus({
          idUsuario: this.storeUser.idUsuario,
          idSolicitud: this.idSolicitud,
          idAcademia: this.profesorSeleccionado,
          claveEstatus: "aprobado",
          idCampus: this.solicitudData.idCampus,
        })
        .subscribe({
          next: (resultOK) => {
            this.toastr.success("Solicitud aprobada con éxito.");
            this.routr.navigateByUrl("/dashboard");
          },
          error: (error) => {
            this.toastr.error("Hubo un error, intenta más tarde.");
            console.log("aprobado", error);
          },
        });
    }
    if (this.mostrarRechazo) {
      this.solicitudesService
        .putSolicitudEstatus({
          idUsuario: this.storeUser.idUsuario,
          idSolicitud: this.idSolicitud,
          claveEstatus: "rechazado",
          idAcademia: 0,
          motivoEstatus: this.motivoRechazo,
          idCampus: this.solicitudData.idCampus,
        })
        .subscribe({
          next: (resultOK) => {
            this.toastr.success("Solicitud rechazada con éxito.");
            this.routr.navigateByUrl("/dashboard");
          },
          error: (error) => {
            this.toastr.error("Hubo un error, intenta más tarde.");
            console.log("rechazado", error);
          },
        });
    }
    if (this.mostrarReasignar) {
      Swal.fire({
        input: "textarea",
        inputLabel: "Motivo de reasignación",
        inputPlaceholder: "Escribe el motivo de reasignación",
        inputAttributes: {
          "aria-label": "Escribe el motivo de reasignación",
        },
        showCancelButton: true,
      }).then((response) => {
        if (response.isConfirmed) {
          this.motivoRechazo = response.value;

          this.solicitudesService
            .putSolicitudEstatus({
              idUsuario: this.storeUser.idUsuario,
              idSolicitud: this.idSolicitud,
              claveEstatus: "reasignado",
              idAcademia: 0,
              idCampus: this.campusSeleccionado,
              motivoEstatus: this.motivoRechazo,
            })
            .subscribe({
              next: (resultOK) => {
                this.toastr.success("Solicitud reasignada con éxito.");
                this.routr.navigateByUrl("/dashboard");
              },
              error: (error) => {
                this.toastr.error("Hubo un error, intenta más tarde.");
                console.log("reasignado", error);
              },
            });
        }
      });
    }
  }

  mostrarAceptarAcademico: boolean = false;
  mostrarRechazo: boolean = false;
  mostrarReasignar: boolean = false;
  motivoRechazo: string = "";
  campusSeleccionado: number;
  estatusSolForm: FormGroup;

  edit: boolean = false;
  async editar() {
    this.initFormVinculacionEdicion();
    this.edit = true;
    this.muestraAlerta = true;
    this.mostrarReasignar = false;
    this.mostrarAceptarAcademico = false;
    this.getEscuela(0);
    this.titleSolicitud.title =
      "Editar solicitud de Socio Formador para vincularse a un reto";

    this.vinculacionForm
      .get("idEscuela")
      ?.patchValue(this.retoInformacion.idEscuela);
    this.getUnidadFormativa(this.retoInformacion.idEscuela);

    this.vinculacionForm
      .get("idUnidadFormativa")
      ?.patchValue(this.retoInformacion.idUnidadFormativa);
    this.getRetoDetail(this.retoInformacion.idUnidadFormativa);
  }

  seleccionarPeriodo(event: any) {
    this.periodoAcademicoSeleccionado = event.target.value;
  }

  seleccionar(event: any) {
    this.campusSeleccionado = event.target.value;
  }

  onCheckboxChange(event: any, typeData: string) {
    if (event.target.checked) {
      const obj = this.campusData.find((x) => {
        return x.idCampus === +event.target.value;
      });
      this.selectedCampus.push(obj!);
    } else {
      const index = this.selectedCampus.findIndex(
        (x) => x.idCampus === +event.target.value
      );
      this.selectedCampus.splice(index, 1);
    }
    document.getElementById("campusReto")?.focus();
  }

  initializeForm() {
    this.vinculacionForm = this.fb.group({
      idEscuela: ["", Validators.required],
      idUnidadFormativa: ["", Validators.required],
    });
  }

  handleCancelar() {
    Swal.fire({
      text: "¿Desea cancelar? Se perderán los cambios.",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.edit = false;
        this.muestraAlerta = true;
        this.titleSolicitud.title =
          "Solicitud de Socio Formador para vincularse a un reto";
      }
    });
  }

  handleGuardar() {
    this.quieroVincularme();
    this.edit = false;
    this.titleSolicitud.title =
      "Solicitud de Socio Formador para vincularse a un reto";
  }

  /************************************* GUARDADO  */
  async quieroVincularme() {

    let claveEstatusSeleccionada = this.estatusSolForm.get("estatus")?.value;

    let idRetoMaestro = this.retoInfo.idRetoMaestro;
    let idPeriodo = this.periodoAcademicoSeleccionado;
    let comentario = this.estatusSolForm.get("comentario")?.value;
    let campus = this.selectedCampus.map((x) => {
      return {
        ...x,
        campusCoordinador: x.idCampus == this.campusSeleccionado ? true : false,
      };
    });

    try {
      let request: ISolicitudRequest = {
        idSolicitud: this.idSolicitud,
        claveEstatus: claveEstatusSeleccionada,
        motivoRechazo: comentario,
        idPeriodo: idPeriodo,
        campus: campus,
        idRetoMaestro: idRetoMaestro,
      };

      await lastValueFrom(this.solicitudesService.putSolicitud(request));

      //await this.saveComment(claveEstatusSeleccionada, comentario);

      this.toastr.success("Solicitud actualizada con éxito.");

      window.history.back();
    } catch (err) {
      console.log(err);
    }

    //   let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
    //   if (this.validaFormulario()) {
    //     this.solicitudesService
    //       .postSolicitud({
    //         idTipoSolicitud: 2,
    //         descripcionSolicitud: `Solicitud del Socio Formador ${this.socioFormador.nombreSocioFormador} para vinculación a Reto ${this.retoDetalle.reto}`,
    //         idSocioFormador: this.userStore.idSocioFormador,
    //         idCVDP: 0,
    //         idAcademia: 0,
    //         idProblematica: 0,
    //         idRetoMaestro: this.retoDetalle.idRetoMaestro,
    //         idCampus: this.CampusSeleccionado,
    //         campusSeleccionados: this.selectedCampus.map(x=> x.idCampus),
    //         idPeriodo: this.periodoAcademicoSeleccionado,
    //         idUsuario: idUsuario
    //       })
    //       .subscribe({
    //         next: (data) => {
    //           if (this.contextoOrigen == "matchReto") {
    //             //Aqui registramos el log de las busquedas, si viene del matchReto
    //             let tmppc = localStorage.getItem("ppc");
    //             let retoSelected = JSON.parse(
    //               localStorage.getItem("retoSelected") ?? "{}"
    //             );

    //             let userStore = this.usuarioService.getCurrentUserData();

    //             let ppc = JSON.parse(tmppc ?? "");
    //             let pmatch = retoSelected.matchScore / 100;

    //             this.retosService.postVinculacionProblematica({
    //               idLogBusquedaReto : this.idLogBusquedaReto,
    //               idRetoMaestro: retoSelected.idReto,
    //               matchScore: retoSelected.matchScore
    //             }).subscribe();

    //             this.retosService
    //               .postMatch({
    //                 idUsuario: userStore.idUsuario,
    //                 parametros: ppc,
    //                 porcentajeMatch: pmatch,
    //                 idSocioFormador: 0,
    //                 idReto: retoSelected.idReto,
    //               })
    //               .subscribe({
    //                 next: (resOK) => {
    //                   localStorage.removeItem("contextoOrigen");
    //                   localStorage.removeItem("ppc");

    //                   this.router.navigate(["reto-confirmacion"]);
    //                 }
    //               });

    //           } else {
    //             localStorage.setItem(
    //               "retoSelected",
    //               JSON.stringify(this.retoDetalle)
    //             );
    //             this.router.navigate(["reto-confirmacion"]);
    //           }
    //         },
    //         error: (error) => {
    //           Swal.fire({
    //             icon: "error",
    //             text: error,
    //           });
    //         }
    //       });
    //   }
  }
  async quieroVincularmeaReto() {
    // this.mostrarRequeridos = true;
    // this.pedirPeriodo = false;
    // this.pedirPeriodoSemanal = false;
    // this.pedirModalidad = false;
    // let idLogBusquedaSocio = sessionStorage.getItem('idLogBusquedaSocio') ?? 0;
    // // Se tiene el arreglo del periodo semanal
    // if(!this.periodoAcademicoSeleccionado)
    //   this.pedirPeriodo = true;
    // if(this.periodoSemanalChk.length == 0)
    //   this.pedirPeriodoSemanal = true;
    // if(this.ModalidadSeleccionada == 0)
    //   this.pedirModalidad = true;
    // if(this.pedirPeriodo || this.pedirPeriodoSemanal||this.pedirModalidad){
    //   return;
    // }
    // this.vinculacionForm.markAllAsTouched();
    // if(this.vinculacionForm.valid){
    // this.solicitudesService
    //   .postSolicitud({
    //     idTipoSolicitud: 5,
    //     descripcionSolicitud: `Solicitud de Vinculación de Reto ${this.retoInfo.reto} a socio Formador ${this.socioFormadorDetalle.nombreSocioFormador}`,
    //     idSocioFormador: this.socioFormadorDetalle.idSocioFormador,
    //     idCVDP: 0,
    //     idAcademia: this.userStore.idUsuario,
    //     idUsuario: this.userStore.idUsuario,
    //     idProblematica: 0,
    //     idRetoMaestro: this.retoInfo.idRetoMaestro,
    //     idCampus: this.userStore.idCampus,
    //     idPeriodo: this.periodoAcademicoSeleccionado,
    //     periodosSemanales: this.periodoSemanalChk,
    //     idModalidad: this.ModalidadSeleccionada
    //   })
    //   .subscribe((data) => {
    //     this.guardarLog();
    //     this.router.navigate(["confirmacion-vinculacion"]);
    //   });
  }

  /*** hast aqui lógica para guardarllo */

  getUnidadFormativa(event) {
    let idEscuela = event;
    this.catalogosService.getUnidadFormativa(idEscuela).subscribe({
      next: (resultado: CUnidadFormativa[]) => {
        this.unidadFormativaData = resultado;
      },
      error: (error) => {
        this.unidadFormativaData = [];
        this.tieneReto = false;
        this.retoInfo = null;
        this.vinculacionForm.get("idUnidadFormativa")?.patchValue({
          idUnidadFormativa: 0,
          clave: "",
          unidadFormativa: "",
        });

        Swal.fire({
          icon: "info",
          text: "No hay Unidades Formativas asociados",
        });
      },
    });
  }

  getEscuela(idEscuela: number) {
    this.catalogosService
      .getEscuelas(idEscuela)
      .pipe(
        tap((resultado: IEscuela[]) => {
          this.escuelaData = resultado;
        })
      )
      .subscribe();
  }

  getRetoDetail(event: any) {
    const idUnidadFormativa = event;
    this.tieneReto = false;
    this.retosService
      .getRetosMaestrosxUnidadFormativa_original(idUnidadFormativa)
      .pipe(
        tap((res: IRetoMaestro[]) => {
          let resultado = res[0];

          this.retosService
            .getRetoMaestroDetail(resultado.idRetoMaestro)
            .subscribe({
              next: (resOK) => {
                this.lbl_idiomas = resOK.idiomas
                  .map((x) => x.idioma)
                  .join(", ");
                this.lbl_semestre = resOK.semestres
                  .map((x) => x.semestre)
                  .join(", ");
                this.lbl_periodoSemanal = resOK.periodosSemanales
                  .map((x) => x.periodoSemanal)
                  .join(", ");
                this.lbl_periodoSemestral = resOK.periodosSemestrales
                  .map((x) => x.periodoSemestral)
                  .join(", ");

                this.tieneReto = true;
                this.retoInfo = resOK;
              },
              error: (err) => {
                console.log(err);
              },
            });
        })
      )
      .subscribe();
  }

  initFormEstatusSolForm() {
    this.estatusSolForm = this.fb.group({
      estatus: [],
      comentario: ["",[Validators.required]],
    });
  }

  verifyChecked(id: string | number, inputForm: string) {
    const areas = this.vinculacionEdicionForm.get(inputForm)?.value;
    if (typeof areas === "object") {
      return areas.includes(id);
    } else {
      return areas === id;
    }
  }

  vinculacionEdicionForm: FormGroup;
  initFormVinculacionEdicion() {
    this.selectedCampus =
      this.solicitudData?.campus?.map((x) => {
        return { ...x, idCampus: Number(x.idCampus) };
      }) ?? [];

    let campusCoord = this.solicitudData?.campus
      .filter((x) => x.campusCoordinador)
      .pop()?.idCampus;

    this.vinculacionEdicionForm = this.fb.group({
      idPeriodo: this.solicitudData.idPeriodo,
      campus: [this.selectedCampus.map((x) => x.idCampus)],
      campusCoordinador: [campusCoord],
    });
  }

  async getProblematicas(idProblematica: number) {
    try {
      let problematica = await lastValueFrom(
        this.problematicasService.getProblematicaId(idProblematica)
      );

      this.lbl_M_areas = problematica.areas.map((x) => x.area).join(",");
      this.lbl_M_alcance = problematica.alcance;
      this.lbl_M_contexto = problematica.problematica;
      this.lbl_M_objetivos = problematica.objetivos;
    } catch (error) {
      console.log(error);
    }
  }

  obtenerEstatusSeguimiento(estatus: string) {
    if (estatus)
      return this.estatusData.find((x) => x.claveEstatus == estatus).estatus;
  }

  async getEstatus() {
    try {
      this.estatusData = await lastValueFrom(
        this.catalogosService.getEstatus()
      );
    } catch (error) {
      console.log(error);
    }
  }

  async getSolicitudComentarios() {
    try {
      this.comentariosData = [];
      this.comentariosData = await lastValueFrom(
        this.solicitudesService.getSolicitudComentarios(
          this.solicitudData.idSolicitud
        )
      );
    } catch (error) {
      console.log(error);
    }
  }

  async saveComment(estatus: string, comentario: string) {
    try {
      let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
      let result = await lastValueFrom(
        this.solicitudesService.postSolicitudComentario({
          idSolicitud: this.solicitudData.idSolicitud,
          claveEstatus: estatus,
          comentarios: comentario,
          idUsuarioRegistro: idUsuario,
        })
      );

      await lastValueFrom(this.solicitudesService
        .putSolicitudEstatus({
          idSolicitud: this.idSolicitud,
          claveEstatus: estatus,
          idUsuario: idUsuario
        }));

    } catch (err) {
      this.toastr.error("Hubo un error al guardar el comentario");
      console.log(err);
    }
  }

  async guardarComentarios() {
    let estatus =
      this.estatusSolForm.get("estatus")?.value ??
      this.solicitudData.claveEstatus;
    let comentario = this.estatusSolForm.get("comentario")?.value;

    if (comentario.length == 0) {
      this.toastr.error("El comentario no puede estar vacio");
      return;
    }

    await this.saveComment(estatus, comentario);

    this.estatusSolForm.reset();

    this.getSolicitudComentarios();
    this.toastr.success("Comentario guardado correctamente");
    this.estatusSolForm.get("comentario")?.patchValue("");
  }

  comentariosData: ISolicitudComentarios[] = [];
  panelOpenState = false;
  estatusData: any = [];
  lblCampusReasignado: string="";



}
